import ASCButton from '@alliancesafetycouncil/asc-button';
import React from 'react';
import { Button, Card, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { Field, FieldArray, reduxForm } from 'redux-form';
import SelectInput from '../../../components/FormContainer/SelectInput';
import TextInput from '../../../components/FormContainer/TextInput';
import { required, validEmail, validPhone } from '../../../library/Validation';

const renderContacts = ({ fields, meta: { error, submitFailed } }) => {
  if (fields.length < 1) {
    fields.push({});
  }

  return (
    <div>
      {fields.map((member, index) => (
        <div key={`muster-contact-list-${index}`} className="grid grid-cols-10 gap-4">
          <div className="col-span-3">
            <Field
              name={`${member}.name`}
              type="text"
              id="contactName"
              label="Name"
              placeholder=""
              component={TextInput}
              className="form-control"
              validate={[required]}
            />
          </div>
          <div className="col-span-3">
            <Field
              name={`${member}.phone`}
              type="text"
              id="contactPhone"
              label="Phone Number"
              placeholder=""
              component={TextInput}
              className="form-control"
              validate={[required, validPhone]}
            />
          </div>
          <div className="col-span-3">
            <Field
              name={`${member}.email`}
              type="email"
              label="Email Address"
              id="email"
              placeholder=""
              component={TextInput}
              className="form-control"
              validate={[validEmail]}
              required={false}
            />
          </div>
          {index !== 0 && (
            <div className="col-span-1 flex items-center mt-13px">
              <Button type="button" title="Remove Hobby" onClick={() => fields.remove(index)}>
                <i className="fal fa-times"></i>
              </Button>
            </div>
          )}
        </div>
      ))}
      <div>
        {submitFailed && error && <span>{error}</span>}
        <Button type="button" onClick={() => fields.push({})}>
          Add Contact
        </Button>
      </div>
    </div>
  );
};

let AddMusterLocationForm = ({ handleSubmit, pristine, busy, sites = [], history }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Card>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="text"
                name="name"
                id="name"
                placeholder=""
                label="Muster Location Name"
                component={TextInput}
                className="form-control"
                validate={[required]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                id="status"
                name="status"
                className="form-control"
                label="Status"
                validate={[required]}
                component={SelectInput}
              >
                <option>Please Select</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                name="site.id"
                id="site.id"
                label="Site"
                component={SelectInput}
                validate={[required]}
                className="form-control"
                disabled={true}
              >
                <option value={undefined}>Please Select</option>
                {sites
                  .filter((sites) => sites.status === 'active')
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((site) => {
                    return (
                      <option key={`site-${site.id}-${site.networkId}`} value={site.id}>
                        {site.name}
                      </option>
                    );
                  })}
              </Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={12}>
            <Card>
              <FieldArray name="contacts" component={renderContacts} />
            </Card>
          </Col>
        </Row>
        <Row form>
          <Col lg={12}>
            <FormGroup>
              <Label for="exampleText" className="primary-heading">
                Notes
              </Label>
              <Field id="notes" name="notes" component="textarea" className="form-control" />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={12}>
            <FormGroup>
              <div className="flex flex-row">
                <div className="mr-2">
                  <ASCButton type="save" size="md" onClick={handleSubmit} disabled={busy}>
                    Save
                  </ASCButton>
                </div>
                <div>
                  <ASCButton type="tertiary" size="md" onClick={() => history.goBack()}>
                    Cancel
                  </ASCButton>
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

AddMusterLocationForm = reduxForm({
  form: 'addMusterLocation',
})(AddMusterLocationForm);

export default AddMusterLocationForm;
