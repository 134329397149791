export const FORGOTPASSWORD_REQUEST = "FORGOTPASSWORD_REQUEST";
export function ForgotPasswordRequest() {
  return {
    type: FORGOTPASSWORD_REQUEST,
  };
}

export const FORGOTPASSWORD_SUCCESS = "FORGOTPASSWORD_SUCCESS";
export function ForgotPasswordSuccess(res) {
  return {
    type: FORGOTPASSWORD_SUCCESS,
    res,
  };
}

export const FORGOTPASSWORD_FAILURE = "FORGOTPASSWORD_FAILURE";
export function ForgotPasswordFailure(error) {
  return {
    type: FORGOTPASSWORD_FAILURE,
    error,
  };
}

export const FORGOTPASSWORD_ACK = "FORGOTPASSWORD_ACK";
export function ForgotPasswordAck() {
  return {
    type: FORGOTPASSWORD_ACK,
  };
}
