import PageTitle from '@alliancesafetycouncil/asc-page-title';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getCompanies } from '../../../actions/Companies/Companies';
import { clearSites, getSitesByNetwork } from '../../../actions/Sites/Sites';
import { TitleBumper } from '../../../components/common';
import FormContainer from '../../../components/FormContainer/FormContainer';
import { getRoles } from '../../../library/Utilities';
import AddUsersForm from './AddUsersForm';

class AddUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      networkId: this.props.match.params.networkId,
      error: false,
      success: false,
      message: null,
      showPassword: false,
    };
  }

  handlePasswordVisibility = (e) => {
    e.preventDefault();

    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  componentDidMount() {
    this.props.ClearSites();
    this.props.GetCompanies('asc', 100, 0, 'id');
  }

  render() {
    const { companies, role, dataSite: sites, siteBusy } = this.props;
    const { showPassword, networkId } = this.state;

    if (!companies) {
      return <div />;
    }

    let data = networkId ? { company: networkId } : {};

    return (
      <div className="flex flex-1 flex-col">
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem tag="a" href="#/users" className="bread-crumb-root">
            Users
          </BreadcrumbItem>
          <BreadcrumbItem>Add Users</BreadcrumbItem>
        </Breadcrumb>
        <TitleBumper>
          <PageTitle icon="user">Add User</PageTitle>
        </TitleBumper>
        <FormContainer
          {...this.props}
          entity="users"
          action="add"
          redirect={'/users'}
          Form={AddUsersForm}
          handleValues={(values) => {
            return {
              name: values.name,
              roles: [values.role],
              sites: ['siteadmin', 'gateguard'].includes(values.role) ? [values.site] : undefined,
              permissions: values.musterAccess ? ['full:muster'] : undefined,
              password: values.password,
              email: values.email,
              phone: values.phone,
              notes: values.notes,
              status: values.status,
            };
          }}
          companies={companies}
          getSites={this.props.GetSitesByNetwork}
          siteBusy={siteBusy}
          sites={sites}
          initialValues={data}
          roles={getRoles(role)}
          handlePasswordVisibility={this.handlePasswordVisibility}
          showPassword={showPassword}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    siteBusy: state.siteService.busy,
    companies: state.companyService.data,
    role: state.authService.role,
    dataSite: state.siteService.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetCompanies: (direction, limit, offset, sort) => dispatch(getCompanies(direction, limit, offset, sort)),
    ClearSites: () => dispatch(clearSites()),
    GetSitesByNetwork: (networkId) => dispatch(getSitesByNetwork(networkId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUsers);
