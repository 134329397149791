import React from 'react';

export default function LogoLight() {
  return (
    <div>
      <svg viewBox="0 0 183.77 56.69">
        <path
          fill="#FFFFFF"
          d="m24.36,56.05l-2.21-6.28h-12.73l-2.21,6.28H0l12.24-31.83h7.09l12.24,31.83h-7.21Zm-4.12-11.89l-4.45-12.54-4.45,12.54h8.89Z"
        />
        <path
          fill="#FFFFFF"
          d="m49.16,23.58c4.01,0,8.53,1.54,12.37,4.51l-1.72,4.79c-3.34-2.18-7.02-3.34-10.26-3.33-5.5-.01-9.44,3.92-9.44,10.58s3.95,10.6,9.46,10.6c3.27,0,7-1.22,10.37-3.44l1.71,4.77c-3.87,3.03-8.48,4.61-12.5,4.61-9.58,0-15.91-7.4-15.91-16.55s6.32-16.58,15.91-16.55h0Z"
        />
        <path
          fill="#FFFFFF"
          d="m83.2,23.58c4.01,0,8.53,1.54,12.37,4.51l-1.71,4.79c-3.34-2.18-7.02-3.34-10.26-3.33-5.5-.01-9.44,3.92-9.44,10.58s3.95,10.6,9.46,10.6c3.27,0,7-1.22,10.37-3.44l1.71,4.77c-3.87,3.03-8.48,4.61-12.5,4.61-9.58,0-15.91-7.4-15.91-16.55s6.32-16.58,15.91-16.55h0Z"
        />
        <path fill="#FFFFFF" d="m124.74,56.05h-22.74v-31.83h22.29v5.6h-15.61v6.76h13.86v5.6h-13.86v8.26h16.05v5.6Z" />
        <path
          fill="#FFFFFF"
          d="m152.89,27.63l-3.63,4.52c-1.88-1.53-4.56-2.65-7.86-2.65-2.99,0-5.04.95-5.18,3.66-.08,1.78.8,2.4,6.31,3.9,4.73,1.3,10.94,2.79,10.99,9.67.05,7.15-6.74,9.95-12.32,9.95-4.8,0-10.02-2.05-12.64-5.45l3.76-4.27c1.96,2.44,5.65,3.85,8.88,3.84,3.46.01,5.44-1.56,5.46-3.84.04-2.38-1.88-2.86-6.26-4.09-7.48-2.12-11.03-3.72-11.04-9.37,0-7.19,5.82-9.92,12.1-9.92,4.77,0,8.74,1.59,11.43,4.05Z"
        />
        <path
          fill="#FFFFFF"
          d="m181.55,27.63l-3.63,4.52c-1.89-1.53-4.56-2.65-7.86-2.65-2.99,0-5.04.95-5.18,3.66-.08,1.78.8,2.4,6.31,3.9,4.73,1.3,10.94,2.79,10.99,9.67.05,7.15-6.74,9.95-12.32,9.95-4.8,0-10.02-2.05-12.64-5.45l3.76-4.27c1.96,2.44,5.65,3.85,8.88,3.84,3.46.01,5.44-1.56,5.46-3.84.04-2.38-1.88-2.86-6.26-4.09-7.48-2.12-11.03-3.72-11.04-9.37,0-7.19,5.82-9.92,12.1-9.92,4.77,0,8.74,1.59,11.43,4.05Z"
        />
        <path
          fill="#FFFFFF"
          d="m134.94,15.58h-2.39c-.16,0-.29-.13-.29-.29v-4.28l-5.27-5.31c-.08-.08-.11-.21-.06-.32.05-.11.15-.18.27-.18h3.22c.08,0,.16.03.21.09l3.29,3.44,3.34-3.44c.05-.06.13-.09.21-.09h2.8c.12,0,.22.07.27.18.04.11.02.23-.06.32l-5.24,5.27v4.33c0,.16-.13.29-.29.29Z"
        />
        <path
          fill="#FFFFFF"
          d="m149.38,15.58h-1.64c-.1,0-.2-.05-.25-.14l-5.93-9.8c-.05-.09-.06-.2,0-.29.05-.09.15-.15.25-.15h2.71c.1,0,.2.05.25.14l3.89,6.67,3.67-6.66c.05-.09.15-.15.25-.15h2.48c.1,0,.2.06.25.15.05.09.05.2,0,.29l-5.68,9.8c-.05.09-.15.14-.25.14Z"
        />
        <path
          fill="#FFFFFF"
          d="m178.73,15.58h-2.39c-.16,0-.29-.13-.29-.29v-7.75h-4.44c-.16,0-.29-.13-.29-.29v-1.76c0-.16.13-.29.29-.29h11.86c.16,0,.29.13.29.29v1.76c0,.16-.13.29-.29.29h-4.46v7.75c0,.16-.13.29-.29.29Z"
        />
        <path
          fill="#FFFFFF"
          d="m164.68,15.61h-3.45c-2.9,0-5.27-2.36-5.27-5.27s2.36-5.27,5.27-5.27h3.45c2.9,0,5.27,2.36,5.27,5.27s-2.36,5.27-5.27,5.27Zm-3.21-8.04c-1.53,0-2.77,1.24-2.77,2.77s1.24,2.77,2.77,2.77h2.97c1.53,0,2.77-1.24,2.77-2.77s-1.24-2.77-2.77-2.77h-2.97Z"
        />
        <path
          fill="#E48E43"
          d="m91.5,7.11c-.19,0-.38.07-.52.19l-.72.6c-.21.17-.31.43-.29.7.03.27.18.5.42.63.12.06.25.1.39.1.19,0,.38-.07.52-.19l.72-.59c.21-.18.31-.43.29-.7-.03-.27-.18-.5-.42-.63-.12-.06-.25-.1-.39-.1Z"
        />
        <path
          fill="#E48E43"
          d="m92.18,17.56h.02c.59.33,1.33.72,1.64.87.35.17.52.31.56.47.03.1,0,.2-.08.32-.06.08-.32.53.05.96.19.22.46.34.78.34.34,0,.67-.14.91-.39.52-.53.58-1.08.54-1.44-.06-.55-.47-1.07-1.14-1.42l-13.78-7.34c-.08-.04-.13-.12-.14-.2,0-.09.03-.17.09-.23l2.74-2.28c.22-.18.33-.46.3-.74-.03-.28-.2-.53-.45-.66-.13-.07-.29-.11-.44-.11-.22,0-.44.08-.61.22l-4.47,3.81c-.11.09-.16.23-.15.37.02.14.1.26.22.33l11.4,6.06c.07.03.11.1.12.17,0,.08-.02.15-.08.2-.21.17-.39.33-.57.47-.24.21-.45.38-.62.51-.48.36-.56.36-.99.18-.03-.01-.05-.04-.09-.07-.12-.11-.35-.32-.78-.32-.39,0-.72.25-.85.64-.12.35-.06.86.46,1.24.47.35.83.47,1.39.47.66,0,1.31-.24,1.94-.72.26-.2.67-.54,1.1-.91.32-.27.65-.55.94-.79l.02-.02Z"
        />
        <path
          fill="#E48E43"
          d="m107.75,4.88c-.22-.23-.65-.76-.98-1.07-.16-.16-.28-.35-.35-.57-.06-.17-.13-.33-.24-.48-.55-.73-1.53-1.23-2.56-1.33-.12-.01-.22-.12-.22-.24v-.56c-.02-.7-.62-.9-1.4-.23-1.45,1.24-1.67,1.43-4.82,3.99-.13.1-.22.17-.22.17-.2.17-.46.28-.72.28-.17,0-.34-.04-.5-.12l-.64-.31c-1.2-.59-2.69-1.32-4.04-2.01-1.51-.76-3.05-1.15-4.58-1.15-2.22,0-4,.82-5.1,1.51-.26.14-.73.48-.74.48-.36.24-2.04,1.44-2.99,2.21-2.77,2.23-5.64,4.54-9.74,4.84-.47.03-.86.41-.87.86,0,.25.08.48.25.66.18.19.43.28.69.26,4.68-.32,7.92-2.93,10.79-5.23.97-.78,1.83-1.42,2.65-1.97l.18-.13c.21-.14.42-.29.65-.43.56-.29,1.16-.44,1.75-.44.41,0,.8.07,1.18.21.87.33,1.46.95,1.65,1.76.25,1.06-.05,1.77-1.09,2.63l-.73.62c-.09.08-.14.19-.12.31.01.12.08.22.18.27l6.38,3.4c.16.09.36.06.5-.05l2.11-1.81c.22-.19.34-.46.33-.74,0-.26-.12-.51-.32-.67-.16-.13-.36-.2-.56-.2s-.42.08-.59.22l-1.39,1.21c-.06.05-.14.06-.21.02l-3.52-1.91s-.06-.05-.06-.09c0-.04,0-.08.03-.11.62-.73,1.21-1.71.81-3.49-.21-.94-.73-1.73-1.54-2.35-.02-.02-.03-.05-.02-.08,0-.03.03-.05.06-.05,1.12.1,2.15.41,3.17.94l.56.3-.58.48c-.2.16-.32.4-.33.65-.01.24.07.45.23.62.17.17.38.26.61.26.2,0,.39-.07.54-.19l1.09-.89c.06-.05.15-.06.23-.02.87.44,1.73.86,2.17,1.08.04.02.07.06.08.11,0,.05-.01.1-.05.13-.09.08-.24.2-.42.35-.22.18-.34.45-.34.74,0,.27.12.5.32.66.16.13.36.2.56.2s.41-.07.57-.21l.95-.79h0s4.53-3.76,4.53-3.76c1.08-.8,2.62-.91,3.57-.23.07.05.05.17-.04.19-.29.06-.91.27-1.09.47-.07.08-.09.17-.06.27.07.24.72.3.96.37.18.05.29.25.48.36.06.04.32.32.51.17.1-.08.11-.38.11-.46,0-.06.07-.09.11-.05.42.4.65.62.65.62.1.11.12.27.03.39l-.58.77c-.05.06-.11.09-.19.09-.5,0-2.42.01-2.67.02-.22,0-.42.08-.59.21l-4.79,4.02h0s-.2.17-.2.17l-1.38,1.16h0s-2.12,1.79-2.12,1.79c-.05.04-.08.11-.07.18,0,.07.05.13.11.16l1.22.65c.12.06.27.05.37-.04l1.72-1.45h0s6.25-5.2,6.25-5.2c0,0,2.03.01,2.7,0,.26,0,.52-.19.81-.54.6-.73,1.11-1.59,1.24-1.78.23-.33.13-.63-.26-1.06Z"
        />
        <path
          fill="#FFFFFF"
          d="m122.69,5.2h-7.5c-.16,0-.29.13-.29.29v9.8c0,.16.13.29.29.29h2.36c.16,0,.29-.13.29-.29v-2.33h4.84c2.14,0,3.88-1.74,3.88-3.88s-1.74-3.88-3.88-3.88Zm-.24,5.36h-4.58v-2.96h4.58c.82,0,1.48.66,1.48,1.48s-.66,1.48-1.48,1.48Z"
        />
      </svg>
    </div>
  );
}
