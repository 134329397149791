import PageTitle from "@alliancesafetycouncil/asc-page-title";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { getEmergency } from "../../../actions/Sites/Sites";
import FormContainer from "../../../components/FormContainer/FormContainer";
import EditEmergencyForm from "./EditEmergencyForm";

class EditEmergency extends Component {
  componentWillMount() {
    const { id, networkId } = this.props.match.params;

    this.props.GetEmergency(id, networkId);
  }

  render() {
    const { id, networkId } = this.props.match.params;

    const { data } = this.props;

    return (
      <div className="flex flex-1 flex-col">
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem tag="a" href="#/emergency" className="bread-crumb-root">
            Emergency
          </BreadcrumbItem>
          <BreadcrumbItem>Edit Emergency</BreadcrumbItem>
        </Breadcrumb>
        <PageTitle icon="gate">Edit Emergency</PageTitle>
        <FormContainer
          {...this.props}
          entity="emergencies"
          action="edit"
          redirect={`/emergency/emergency-details/${id}/${networkId}/details`}
          Form={EditEmergencyForm}
          handleValues={(values) => {
            return {
              name: values.name,
              notes: values.notes,
            };
          }}
          initialValues={data}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.siteService.emergency,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetEmergency: (emergencyId, networkId) => dispatch(getEmergency(emergencyId, networkId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditEmergency);
