import {
  CLEAR_ACTIVITY_DATA,
  GET_ACTIVITY_FAILURE,
  GET_ACTIVITY_REQUEST,
  GET_ACTIVITY_SUCCESS,
} from '../../actions/Activity/Activity';

const InitialState = {
  busy: false,
  res: false,
  error: false,
  data: [],
  page: 1,
  pagination: {
    totalItems: 0,
  },
  noData: false,
};

export default function activityService(state = InitialState, action) {
  switch (action.type) {
    case CLEAR_ACTIVITY_DATA:
      return {
        ...state,
        data: [],
      };
    case GET_ACTIVITY_REQUEST:
      return {
        ...state,
        noData: false,
        busy: true,
        res: false,
      };
    case GET_ACTIVITY_SUCCESS:
      let noData = state.noData;
      if (!action.data.length && !state.data.length) {
        noData = true;
      }

      return {
        ...state,
        busy: false,
        res: true,
        data: [...state.data, ...action.data],
        offset: action.data[action.data.length - 1] && action.data[action.data.length - 1].lastEvent,
        pagination: action.pagination,
        page: action.page ? action.page : 1,
        noData: noData,
      };
    case GET_ACTIVITY_FAILURE:
      return {
        ...state,
        busy: false,
        err: true,
      };
    default:
      return state;
  }
}
