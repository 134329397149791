import { combineReducers } from "redux";
import authService from "./Auth/Auth";
import companyService from "./Companies/Companies";
import gateService from "./Gates/Gates";
import networkService from "./Networks/Networks";
import siteService from "./Sites/Sites";
import userService from "./Users/Users";
import statusService from "./Status/Status";
import forgotPasswordService from "./ForgotPassword/ForgotPassword";
import formService from "./Form/Form";
import toastService from "./Toast/Toast";
import profileService from "./Profile/Profile";
import activityService from "./Activity/Activity";
import visitorService from "./Visitors/Visitors";
import { reducer as formReducer } from "redux-form";

const rootReducer = combineReducers({
  authService,
  companyService,
  gateService,
  siteService,
  userService,
  statusService,
  forgotPasswordService,
  formService,
  toastService,
  profileService,
  networkService,
  activityService,
  visitorService,
  form: formReducer,
});

export default rootReducer;
