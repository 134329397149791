import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Col, FormGroup, Row } from "reactstrap";
import { cancelStatusRequest, handleStatusRequest, setStatus } from "../../../actions/Status/Status";

const Contact = ({ data }) => (
  <div className="muster contact-list contact">
    <div>
      {data.name}
      <br />
    </div>
    <div>
      <a href={`tel:${data.phone}`}>{data.phone}</a>
      <br />
    </div>
    <a href={`mailto:${data.email}`}>{data.email}</a>
  </div>
);

const Contacts = ({ list }) => (
  <Row form className="muster contact-list">
    <strong>Contacts</strong>
    <Card>
      {list.map((contact) => (
        <Contact data={contact} />
      ))}
    </Card>
  </Row>
);

class Details extends Component {
  handleDeactivate = (e) => {
    e.preventDefault();

    const modalConfig = {
      show: true,
      button: "Yes, deactivate",
      body: `Are you sure you want to deactivate`,
      name: ` ${this.props.details.name}?`,
      action: () =>
        this.props.SetStatus(
          "locations",
          false,
          `${this.props.match.params.id}?networkId=${this.props.match.params.networkId}`
        ),
      cancel: () => this.props.CancelStatusRequest(),
    };

    this.props.HandleStatusRequest(modalConfig);
  };

  handleActivate = (e) => {
    e.preventDefault();

    const modalConfig = {
      show: true,
      button: "Yes, activate",
      body: `Are you sure you want to activate`,
      name: ` ${this.props.details.name}?`,
      action: () =>
        this.props.SetStatus(
          "locations",
          true,
          `${this.props.match.params.id}?networkId=${this.props.match.params.networkId}`
        ),
      cancel: () => this.props.CancelStatusRequest(),
    };

    this.props.HandleStatusRequest(modalConfig);
  };

  render() {
    const { details } = this.props;

    const { status, notes, contacts } = details;

    return (
      <Card>
        <Contacts list={contacts} />
        <Row form>
          <Col md={6}>
            <FormGroup>
              <div>
                <strong>Status:</strong>
              </div>
              {status && (
                <div className={`details status ${status}`}>
                  {status}{" "}
                  {status === "active" ? (
                    <a
                      href="#/"
                      onClick={(e) => {
                        this.handleDeactivate(e);
                      }}
                    >
                      (Deactivate)
                    </a>
                  ) : (
                    <a
                      href="#/"
                      onClick={(e) => {
                        this.handleActivate(e);
                      }}
                    >
                      (Activate)
                    </a>
                  )}{" "}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <br></br>
        <Row form>
          <Col md={6}>
            <p>
              <strong>Notes</strong>
              <br />
              {notes}
            </p>
          </Col>
        </Row>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    details: state.siteService.location,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    HandleStatusRequest: (modalConfig) => dispatch(handleStatusRequest(modalConfig)),
    CancelStatusRequest: () => dispatch(cancelStatusRequest()),
    SetStatus: (entity, status, id) => dispatch(setStatus(entity, status, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
