import { ACK_TOAST, SET_TOAST_FAILURE, SET_TOAST_SUCCESS } from "../../actions/Toast/Toast";

const InitialState = {
  success: false,
  failure: false,
  err: null,
};

export default function toastService(state = InitialState, action) {
  switch (action.type) {
    case ACK_TOAST:
      return InitialState;
    case SET_TOAST_SUCCESS:
      return {
        ...state,
        success: true,
      };
    case SET_TOAST_FAILURE:
      return {
        ...state,
        failure: true,
        err: action.config.err || "Request Failed",
      };
    default:
      return state;
  }
}
