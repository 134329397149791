import React from 'react';

const TextInput = ({
  input,
  label,
  type,
  name,
  id,
  placeholder,
  required = true,
  disabled = false,
  meta: { touched, error, warning },
}) => (
  <div className="form-group">
    <label htmlFor={name} className="primary-heading">
      {required && <span className={`form-required ${touched && error && 'active'}`}>*&nbsp;</span>}
      {label}
    </label>
    <div>
      <input
        id={id}
        name={name}
        {...input}
        placeholder={placeholder || label}
        type={type}
        className="form-control"
        disabled={disabled}
      />
      {touched &&
        ((error && <span className="form-error">{error}</span>) ||
          (warning && <span className="form-warning">{warning}</span>))}
    </div>
  </div>
);

export default TextInput;
