module.exports = {
  oneOf: (values) => (value) => (!values.includes(value) ? "This field is required" : undefined),

  positiveInteger: (value) => (value < 1 ? "This needs to be at least 1" : undefined),

  required: (value) => (!value ? "This field is required" : undefined),

  validEmail: (value) =>
    value &&
    // eslint-disable-next-line
    !/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      value
    )
      ? "Please enter a valid email address"
      : undefined,

  validPassword: (value) =>
    value && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{10,}$/.test(value)
      ? "Please enter a valid password"
      : undefined,

  validPhone: (value) =>
    // eslint-disable-next-line
    value && !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(value)
      ? "Please enter a valid US number"
      : undefined,

  validNumber: (value) => (value && !/[0-9]/.test(value) ? "Please enter a valid number" : undefined),

  minLength: (values) => (values.length < 1 ? "Please add at leat one contact" : undefined),
};
