import React from 'react';

import useThemeConfig from '../Theme/ThemeLoader';
import Logo from './Logo';
import LogoDefault from './LogoDefault';
import LogoLight from './LogoLight';
import LogoLightDefault from './LogoLightDefault';

export default function LogoTheme({ type }) {
  const themeConfig = useThemeConfig();

  if (themeConfig.error) {
    return 'error';
  }

  const name = themeConfig.config?.theme[type];

  const components = {
    Logo: <Logo />,
    LogoLight: <LogoLight />,
    LogoDefault: <LogoDefault />,
    LogoLightDefault: <LogoLightDefault />,
  };

  return components[name] || null;
}
