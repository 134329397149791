import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, Row } from 'reactstrap';
import { getSiteActivity } from '../../../actions/Sites/Sites';
import ActivityReport from '../../../components/ActivityReport/ActivityReport';
import { DateFilter } from '../../../components/ActivityReport/DateFilter';

class ActivityTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      filter: '',
      export: null,
      csv: null,
      startDate: null,
      endDate: null,
    };
  }

  setContext = (context) => {
    this.setState({
      export: context.excel,
      csv: context.csv,
    });
  };

  export = () => {
    this.state.export();
  };

  csv = () => {
    this.state.csv();
  };

  toggleDropdown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleDate = (value, date) => {
    this.setState({
      [date]: value,
    });
  };

  render() {
    const { startDate, endDate } = this.state;

    const { networkId, id } = this.props.match.params;

    return (
      <div
        style={{
          flex: '1',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Row
          className="mb-3 mt-1 text-left"
          style={{
            zIndex: '999',
            position: 'relative',
            display: 'inline-flex',
            alignSelf: 'flex-start',
            flexDirection: 'row',
          }}
        >
          <Col>
            <div className="options">
              <Form style={{ display: 'flex' }}>
                <Input
                  value={this.state.filter}
                  type="text"
                  name="filter"
                  id="filter"
                  placeholder="Filter by anything"
                  className="filter-anything"
                  onChange={(e) => this.handleChange(e)}
                />
                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown} className="export">
                  <DropdownToggle caret color="secondary">
                    Export
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-right">
                    <DropdownItem onClick={() => this.export()}>Excel</DropdownItem>
                    <DropdownItem onClick={() => this.csv()}>CSV</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <div className="filter-date">
                  <DateFilter startDate={startDate} endDate={endDate} handleDate={this.handleDate} />
                </div>
              </Form>
            </div>
          </Col>
        </Row>
        <div
          className="ag-grid-wrap"
          style={{
            flex: '1',
            display: 'flex',
          }}
        >
          <div
            className="ag-theme-balham"
            style={{
              flex: '1',
              width: '100%',
            }}
          >
            <ActivityReport
              getData={(offset, paginate, page) => this.props.GetSiteActivity(networkId, id, offset, paginate, page)}
              setContext={this.setContext}
              filter={this.state.filter}
              busy={this.props.busy}
              noData={this.props.noData}
              res={this.props.res}
              err={this.props.err}
              data={this.props.data}
              pagination={this.props.pagination}
              page={this.props.page}
              startDate={startDate}
              endDate={endDate}
              offset={this.props.offset}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    offset: state.siteService.offset,
    noData: state.siteService.noData,
    busy: state.siteService.busy,
    res: state.siteService.res,
    err: state.siteService.err,
    data: state.siteService.activity,
    pagination: state.siteService.pagination,
    page: state.siteService.page,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetSiteActivity: (networkId, siteId, offset, paginate, page) =>
      dispatch(getSiteActivity(networkId, siteId, offset, paginate, page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityTab);
