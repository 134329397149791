import React from 'react';

import { connect } from 'react-redux';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import ASCButton from '@alliancesafetycouncil/asc-button';
import MainCard from '@alliancesafetycouncil/asc-card';

import GateEntryInput from '../../../components/FormContainer/GateEntryInput';
import SelectInput from '../../../components/FormContainer/SelectInput';
import TextInput from '../../../components/FormContainer/TextInput';
import { required, validEmail } from '../../../library/Validation';

let EditCompanyForm = ({ username, password, handleSubmit, history, role, busy, entry }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <MainCard type="main">
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                id="name"
                name="name"
                component={TextInput}
                type="text"
                label="Company Name"
                placeholder=""
                className="form-control"
                validate={[required]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                id="status"
                name="status"
                className="form-control"
                label="Status"
                validate={[required]}
                component={SelectInput}
              >
                {' '}
                <option>Please Select</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Label for="status" className="primary-heading">
                Emergency/Muster List
              </Label>
              <Row form>
                <Col lg={1}>
                  <Field
                    id="hasMusterFeature"
                    name="hasMusterFeature"
                    className="form-control-input"
                    label="Emergency/Muster List"
                    component="input"
                    type="checkbox"
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Label for="status" className="primary-heading">
                Block List
              </Label>
              <Row form>
                <Col lg={1}>
                  <Field
                    id="hasBlockFeature"
                    name="hasBlockFeature"
                    className="form-control-input"
                    label="Block List"
                    component="input"
                    type="checkbox"
                  />
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            {/* <FormGroup>
              <Label for="status" className="primary-heading">
                Guard Override
              </Label>
              <Row form>
                <Col lg={1}>
                  <Field
                    id="hasGuardOverride"
                    name="hasGuardOverride"
                    className="form-control-input"
                    label="Enable"
                    component="input"
                    type="checkbox"
                  />
                </Col>
              </Row>
            </FormGroup> */}
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                id="companyContactName"
                name="contact.name"
                component={TextInput}
                type="text"
                placeholder=""
                label="Company Contact (Name)"
                className="form-control"
                validate={[required]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                id="contactPhone"
                name="contact.phone"
                component={TextInput}
                type="text"
                placeholder=""
                label="Contact Phone"
                className="form-control"
                validate={[required]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                id="email"
                name="contact.email"
                component={TextInput}
                type="email"
                placeholder=""
                label="Contact Email Address"
                className="form-control"
                validate={[required, validEmail]}
              />
            </FormGroup>
          </Col>
        </Row>
        <GateEntryInput username={username} password={password} isEdit={true} entry={entry} />
        <Row form>
          <Col lg={12}>
            <FormGroup>
              <Label for="exampleText" className="primary-heading">
                Notes
              </Label>
              <Field
                id="notes"
                name="notes"
                component="textarea"
                className="form-control"
                // validate={[required]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={12}>
            <FormGroup>
              <div className="flex flex-row">
                <div className="mr-2">
                  <ASCButton type="save" size="md" onClick={handleSubmit} disabled={busy}>
                    Save
                  </ASCButton>
                </div>
                <div>
                  <ASCButton type="tertiary" size="md" onClick={() => history.goBack()}>
                    Cancel
                  </ASCButton>
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </MainCard>
    </Form>
  );
};

EditCompanyForm = reduxForm({
  form: 'editCompany',
  enableReinitialize: true,
})(EditCompanyForm);

const selector = formValueSelector('editCompany');
EditCompanyForm = connect((state) => {
  const username = selector(state, 'entry.iLevel.username');
  const password = selector(state, 'entry.iLevel.password');
  const entry = selector(state, 'entry');

  return {
    username,
    password,
    entry,
  };
})(EditCompanyForm);

export default EditCompanyForm;
