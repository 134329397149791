import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Col, FormGroup, Row } from "reactstrap";
import { cancelStatusRequest, handleStatusRequest, setStatus } from "../../../actions/Status/Status";
import {
  cancelPermissionsRequest,
  handlePermissionsRequest,
  updateUserPermissions,
} from "../../../actions/Users/Users";
import { getRoles } from "../../../library/Utilities";

class DetailsTab extends Component {
  handleDeactivate = (e) => {
    e.preventDefault();

    const modalConfig = {
      show: true,
      button: "Yes, lock account",
      body: `Are you sure you want to lock`,
      name: ` ${this.props.details.name.first} ${this.props.details.name.last}?`,
      action: () =>
        this.props.SetStatus(
          "users",
          false,
          `${this.props.match.params.id}${
            this.props.match.params.networkId ? `?networkId=${this.props.match.params.networkId}` : ""
          }`
        ),
      cancel: () => this.props.CancelStatusRequest(),
    };

    this.props.HandleStatusRequest(modalConfig);
  };

  handleActivate = (e) => {
    e.preventDefault();

    const modalConfig = {
      show: true,
      button: "Yes, unlock account",
      body: `Are you sure you want to unlock`,
      name: ` ${this.props.details.name.first} ${this.props.details.name.last}?`,
      action: () =>
        this.props.SetStatus(
          "users",
          true,
          `${this.props.match.params.id}${
            this.props.match.params.networkId ? `?networkId=${this.props.match.params.networkId}` : ""
          }`
        ),
      cancel: () => this.props.CancelStatusRequest(),
    };

    this.props.HandleStatusRequest(modalConfig);
  };

  handleMusterRemoveAccess = (e) => {
    e.preventDefault();

    const modalConfig = {
      show: true,
      button: "Yes, remove access",
      body: `Are you sure you want to remove muster access for`,
      name: ` ${this.props.details.name.first} ${this.props.details.name.last}?`,
      action: () =>
        this.props.UpdateUserPermissions(
          [],
          `${this.props.match.params.id}${
            this.props.match.params.networkId ? `?networkId=${this.props.match.params.networkId}` : ""
          }`
        ),
      cancel: () => this.props.CancelPermissionsRequest(),
    };

    this.props.HandlePermissionsRequest(modalConfig);
  };

  handleMusterGrantAccess = (e) => {
    e.preventDefault();

    const modalConfig = {
      show: true,
      button: "Yes, grant access",
      body: `Are you sure you want to grant muster access for`,
      name: ` ${this.props.details.name.first} ${this.props.details.name.last}?`,
      action: () =>
        this.props.UpdateUserPermissions(
          ["full:muster"],
          `${this.props.match.params.id}${
            this.props.match.params.networkId ? `?networkId=${this.props.match.params.networkId}` : ""
          }`
        ),
      cancel: () => this.props.CancelPermissionsRequest(),
    };

    this.props.HandlePermissionsRequest(modalConfig);
  };

  render() {
    const {
      details: { status, notes, name, email, phone, roles, permissions },
    } = this.props;
    const role = getRoles("superadmin").find((x) => x.value === roles);

    return (
      <Card>
        <Row form>
          <Col md={role && role.value === "gateguard" ? 4 : 6}>
            <div>
              <strong>Contact:</strong>
              <br />
              <div>
                {name.first} {name.last}
                <br />
              </div>
              <div>{role ? role.label : roles}</div>
              <div>
                <a href={`tel:${phone}`}>{phone}</a>
                <br />
              </div>
              <a href={`mailto:${email}`}>{email}</a>
            </div>
          </Col>
          <Col md={role && role.value === "gateguard" ? 4 : 6}>
            <FormGroup>
              <div className="primary-heading">
                <strong>Status:</strong>
              </div>
              {status && (
                <div className={`details status ${status}`}>
                  {status === "active" ? "Active" : "Login Locked"}{" "}
                  {status === "active" ? (
                    <a
                      href="#/"
                      onClick={(e) => {
                        this.handleDeactivate(e);
                      }}
                    >
                      (Lock)
                    </a>
                  ) : (
                    <a
                      href="#/"
                      onClick={(e) => {
                        this.handleActivate(e);
                      }}
                    >
                      (Unlock)
                    </a>
                  )}{" "}
                </div>
              )}
            </FormGroup>
          </Col>
          {role && role.value === "gateguard" && (
            <Col md={4}>
              <FormGroup>
                <div className="primary-heading">
                  <strong>Muster Access:</strong>
                </div>
                {permissions && (
                  <div className={`details status`}>
                    {permissions.includes("full:muster") ? "Yes" : "No"}{" "}
                    {permissions.includes("full:muster") ? (
                      <a
                        href="#/"
                        onClick={(e) => {
                          this.handleMusterRemoveAccess(e);
                        }}
                      >
                        (Remove Access)
                      </a>
                    ) : (
                      <a
                        href="#/"
                        onClick={(e) => {
                          this.handleMusterGrantAccess(e);
                        }}
                      >
                        (Grant Access)
                      </a>
                    )}{" "}
                  </div>
                )}
              </FormGroup>
            </Col>
          )}
        </Row>
        <br></br>
        <p>
          <strong>Notes</strong>
          <br />
          {notes}
        </p>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    details: state.userService.details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    HandleStatusRequest: (modalConfig) => dispatch(handleStatusRequest(modalConfig)),
    CancelStatusRequest: () => dispatch(cancelStatusRequest()),
    SetStatus: (entity, status, id) => dispatch(setStatus(entity, status, id)),
    HandlePermissionsRequest: (modalConfig) => dispatch(handlePermissionsRequest(modalConfig)),
    CancelPermissionsRequest: () => dispatch(cancelPermissionsRequest()),
    UpdateUserPermissions: (permissions, id) => dispatch(updateUserPermissions(permissions, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsTab);
