import React from 'react';

import { NavLink } from 'react-router-dom';

import LogoTheme from '../../components/Logo/LogoTheme';

export const Sidenav = ({ SignOut, Profile }) => {
  const companyId = window.user.company_id;
  const siteId = window.user.site_id;
  const networkId = window.user.network_id;
  const role = window.user.role;

  return (
    <div className="z-40 w-56 h-screen bg-blue-900 rounded-tr-xl rounded-br-xl">
      {/* Large Screens */}
      <div className="hidden text-center bg-blue-500 md:block rounded-tr-xl">
        <NavLink className="flex justify-center" to="/">
          <div className="py-6 w-36">
            <LogoTheme type="logoLight" />
          </div>
        </NavLink>
      </div>
      {/* Small Screens */}
      <div className="py-2 text-center bg-blue-500 md:hidden">
        <div className="text-3xl text-white">
          <span className="font-bold">Menu</span>
        </div>
      </div>
      {/* Menu Items Wrapper */}
      <div className="flex flex-col flex-1 mt-4">
        {/* "Companies" Tab for Superadmin */}
        {role === 'superadmin' && (
          <NavLink
            activeClassName="bg-blue-800 rounded-full"
            className="flex items-center px-4 py-3 mx-3 text-white my-10px hover:no-underline"
            to="/companies"
          >
            <i className="mr-2 text-xl text-orange-500 fal fa-building"></i>
            All Companies
          </NavLink>
        )}
        {/* "My Company" Tab for Owneradmin */}
        {role === 'owneradmin' && (
          <NavLink
            activeClassName="bg-blue-800 rounded-full"
            className="flex items-center px-4 py-3 mx-3 text-white my-10px hover:no-underline"
            to={'/companies/company-details/' + networkId + '/' + companyId + '/activity-report'}
          >
            <i className="mr-2 text-xl text-orange-500 fal fa-building"></i>
            My Company
          </NavLink>
        )}
        {/* "My Site" Tab for Siteadmin */}
        {role === 'siteadmin' && (
          <NavLink
            activeClassName="bg-blue-800 rounded-full"
            className="flex items-center px-4 py-3 mx-3 text-white my-10px hover:no-underline"
            to={'/sites/site-details/' + siteId + '/' + networkId + '/activity-report'}
          >
            <i className="mr-2 text-xl text-orange-500 fal fa-map-marker-alt"></i>
            My Site
          </NavLink>
        )}
        {/* "All SiteS" Tab for Superadmin and Owneradmin */}
        {role && ['superadmin', 'owneradmin'].includes(role) && (
          <NavLink
            activeClassName="bg-blue-800 rounded-full"
            className="flex items-center px-4 py-3 mx-3 text-white my-10px hover:no-underline"
            to="/sites"
          >
            <i className="mr-2 text-xl text-orange-500 fal fa-map-marker-alt"></i>
            All Sites
          </NavLink>
        )}
        <NavLink
          activeClassName="bg-blue-800 rounded-full"
          className="flex items-center px-4 py-3 mx-3 text-white my-10px hover:no-underline"
          to="/gates"
        >
          <i className="mr-2 text-xl text-orange-500 fal fa-door-open"></i>
          All Gates
        </NavLink>
        <NavLink
          activeClassName="bg-blue-800 rounded-full"
          className="flex items-center px-4 py-3 mx-3 text-white my-10px hover:no-underline"
          to="/users"
        >
          <i className="mr-2 text-xl text-orange-500 fal fa-users"></i>
          All Users
        </NavLink>
        <NavLink
          activeClassName="bg-blue-800 rounded-full"
          className="flex items-center px-4 py-3 mx-3 text-white my-10px hover:no-underline"
          to="/visitors"
        >
          <i className="mr-2 text-xl text-orange-500 fal fa-door-open"></i>
          All Visitors
        </NavLink>
        <NavLink
          activeClassName="bg-blue-800 rounded-full"
          className="flex items-center px-4 py-3 mx-3 text-white my-10px hover:no-underline"
          to="/profile"
        >
          <i className="mr-2 text-xl text-orange-500 fal fa-user-circle"></i>
          My Profile
        </NavLink>
        <NavLink
          activeClassName="bg-blue-800 rounded-full"
          className="flex items-center px-4 py-3 mx-3 text-white my-10px hover:no-underline"
          to="/support"
        >
          <i className="mr-2 text-xl text-orange-500 fal fa-question-circle"></i>
          Support
        </NavLink>
        <NavLink
          className="flex items-center px-4 py-3 mx-3 text-white my-10px hover:no-underline"
          to="#"
          onClick={() => SignOut()}
        >
          <i className="mr-2 text-xl text-orange-500 fal fa-sign-out-alt"></i>
          Sign Out
        </NavLink>
        <a
          className="flex items-center mr-2 text-sm text-white"
          href="https://alliancesafetycouncil.org/privacy-policy/"
          target="_blank"
          style={{
            bottom: 0,
            position: 'absolute',
            width: '100%',
          }}
        >
          <p
            style={{
              paddingLeft: '22.5px',
              paddingBottom: '10px',
            }}
          >
            Privacy Policy
          </p>
        </a>
      </div>
    </div>
  );
};
