import PageTitle from '@alliancesafetycouncil/asc-page-title';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem, Toast, ToastBody } from 'reactstrap';
import { getGate } from '../../../actions/Gates/Gates';
import { ackToast } from '../../../actions/Status/Status';
import TabDisplay from '../../../components/TabDisplay/TabDisplay';
import { TitleBumper } from '../../../components/common';
import DetailsTab from './DetailsTab';

class GateDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      config: [
        {
          name: 'Details',
          path: '/details',
          component: DetailsTab,
          default: true,
          exact: true,
        },
      ],
    };
  }

  // componentWillMount() {
  //   const { id, networkId } = this.props.match.params;

  //   this.props.GetGate(id, networkId);
  // }

  componentDidMount() {
    const { id, networkId } = this.props.match.params;
    this.props.GetGate(id, networkId);
  }

  render() {
    const {
      AckToast,
      busy,
      res,
      err,
      details,
      match: {
        params: { id, networkId },
      },
    } = this.props;

    const { company, site, name } = details;

    const isSuccess = !busy && res;
    const isFailure = !busy && err;

    if (isSuccess || isFailure) {
      const timer = setTimeout(() => {
        AckToast();
        clearTimeout(timer);
      }, 5000);
    }

    return (
      <div className="flex flex-1 flex-col">
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem tag="a" href={`/#/companies/company-details/${networkId}/${company.id}/activity-report`}>
            {company.name}
          </BreadcrumbItem>
          <BreadcrumbItem tag="a" href={`/#/sites/site-details/${site.id}/${networkId}/gates`}>
            {site.name}
          </BreadcrumbItem>
          <BreadcrumbItem active>{name}</BreadcrumbItem>
        </Breadcrumb>
        <TitleBumper>
          <PageTitle icon="gate" editLink={`#/gates/edit-gate/${id}/${networkId}`}>
            {name}
          </PageTitle>
        </TitleBumper>
        <TabDisplay
          {...this.props}
          tabs={this.state.config}
          edit={{
            path: `#/gates/edit-gate/${id}/${networkId}`,
            label: 'Edit Gate',
          }}
        />
        <Toast onClick={AckToast} isOpen={isSuccess} className="toast success">
          <ToastBody>Success!</ToastBody>
        </Toast>
        <Toast onClick={AckToast} isOpen={isFailure} className="toast error">
          <ToastBody>
            <p>{err && err.message}</p>
          </ToastBody>
        </Toast>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.statusService.busy,
    res: state.statusService.res,
    err: state.statusService.err,
    details: state.gateService.details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetGate: (gateId, networkId) => dispatch(getGate(gateId, networkId)),
    AckToast: () => dispatch(ackToast()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GateDetails);
