import MainCard from '@alliancesafetycouncil/asc-card';
import PageTitle from '@alliancesafetycouncil/asc-page-title';
import ASCButton from '@alliancesafetycouncil/asc-button';
import React from 'react';
import { Breadcrumb, BreadcrumbItem, Button, Card, Col, Row } from 'reactstrap';
import { TitleBumper } from '../../components/common';

const DetailsTab = ({ profile }) => (
  <div className="flex flex-1 flex-col">
    <Breadcrumb tag="nav" listTag="div">
      <BreadcrumbItem tag="a" active>
        My Profile
      </BreadcrumbItem>
    </Breadcrumb>
    <TitleBumper>
      <PageTitle icon="profile">My Profile</PageTitle>
    </TitleBumper>
    <MainCard type="main">
      <div className="mb-2">
        <strong>My Information</strong>
      </div>
      <Card>
        <Row form>
          <Col md={6}>
            <div>
              {profile.name.first} {profile.name.last}
              <br />
            </div>
            <div>
              {profile.roles}
              <br />
            </div>
            <div>
              <a href={`tel:${profile.phone}`}>{profile.phone}</a>
              <br />
            </div>
            <a href={`mailto:${profile.email}`}>{profile.email}</a>
          </Col>
          <Col md={6} style={{ textAlign: 'right' }}>
            <ASCButton size="md" type="primary" href="#/profile/edit-profile">
              Edit
            </ASCButton>
          </Col>
        </Row>
      </Card>
      <div className="mb-2 mt-4">
        <strong>Password</strong>
      </div>
      <Card>
        <Row form>
          <Col md={6}>
            <strong>Current Password:</strong>
            <p>*******</p>
          </Col>
          <Col md={6} style={{ textAlign: 'right' }}>
            <ASCButton size="md" type="secondary" href="#/profile/change-password">
              Change
            </ASCButton>
          </Col>
        </Row>
      </Card>
    </MainCard>
  </div>
);

export default DetailsTab;
