import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

export const DeactivateModal = ({ modalConfig }) => {
  const { button, body, name, action, cancel, show } = modalConfig;

  return (
    <div>
      <Modal isOpen={show} className={"className"}>
        <ModalBody>
          {body}
          <strong>{name}</strong>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={action}>
            {button}
          </Button>{" "}
          <Button color="secondary" onClick={cancel}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeactivateModal;
