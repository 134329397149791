import React from 'react';

export default function LogoDefault() {
  return (
    <div className="mb-6 text-4xl text-center">
      <span className="font-bold text-black-600">Gate</span>
      <span className="font-light text-blue-500">Check</span>
    </div>
  );
}
