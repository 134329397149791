import ASCButton from '@alliancesafetycouncil/asc-button';
import MainCard from '@alliancesafetycouncil/asc-card';
import React from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { Field, Form, reduxForm } from 'redux-form';
import SelectInput from '../../../components/FormContainer/SelectInput';
import TextInput from '../../../components/FormContainer/TextInput';
import { required, validEmail, validPhone } from '../../../library/Validation';

let AddGatesForm = ({
  handleNetworkId,
  handleSubmit,
  pristine,
  submitting,
  company,
  getCompany,
  companies = ['Select Company'],
  sites = [],
  getSites,
  history,
  siteBusy,
  networkBusy,
  getCurriculums,
  curriculums,
}) => {
  const companyId = (networkId) => companies?.find((c) => c?.network?.id === networkId)?.id;
  const cq = company?.entry?.cq?.enabled;

  return (
    <Form onSubmit={handleSubmit}>
      <MainCard type="main">
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="text"
                name="name"
                id="gateName"
                placeholder=""
                label="Gate Name"
                component={TextInput}
                className="form-control"
                validate={[required]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                id="status"
                name="status"
                className="form-control"
                label="Status"
                validate={[required]}
                component={SelectInput}
              >
                {' '}
                <option>Please Select</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                id="company"
                name="company"
                label="Company"
                className="form-control"
                component={SelectInput}
                validate={required}
                onChange={(e) => {
                  const id = companyId(e.target.value);
                  if (id) getCompany(companyId(e.target.value));
                  getSites(e.target.value);
                  getCurriculums(e.target.value);
                  handleNetworkId(e.target.value);
                }}
              >
                <option value={undefined}>Please Select</option>
                {companies
                  .filter((sites) => sites.status === 'active')
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((company) => {
                    return (
                      <option key={company.network.id} value={company.network.id}>
                        {company.name}
                      </option>
                    );
                  })}
              </Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Label for="status" className="primary-heading">
                Enable/Disable CQ
              </Label>
              <Row form>
                <Col lg={1}>
                  <Field
                    id="cq-client-enabled"
                    name="entry.cq.enabled"
                    className="form-control-input"
                    label="Enable/Disable CQ"
                    component="input"
                    type="checkbox"
                    disabled={!cq}
                  />
                  {cq ? null : (
                    <Row style={{ paddingTop: 10, whiteSpace: 'nowrap' }}>
                      Please contact your administrator to enable CQ
                    </Row>
                  )}
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                name="site.id"
                id="site"
                placeholder=""
                label="Site"
                component={SelectInput}
                validate={required}
                className="form-control"
                disabled={siteBusy}
              >
                <option value={''}>{siteBusy ? 'Loading...' : 'Please Select'}</option>
                {sites
                  .filter((sites) => sites.status === 'active')
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((site) => {
                    return (
                      <option key={site.id} value={site.id}>
                        {site.name}
                      </option>
                    );
                  })}
              </Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="text"
                name="contact.name"
                id="gateContactName"
                label="Gate Contact (Name)"
                placeholder=""
                component={TextInput}
                className="form-control"
                required={false}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="text"
                name="contact.phone"
                id="contactPhone"
                label="Contact Phone"
                placeholder=""
                component={TextInput}
                className="form-control"
                validate={[validPhone]}
                required={false}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="email"
                name="contact.email"
                component={TextInput}
                label="Contact Email Address"
                id="email"
                placeholder=""
                className="form-control"
                validate={[validEmail]}
                required={false}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                id="ilevelCurriculum"
                name="config.entry.iLevel.curriculum"
                label="iLevel Curriculum"
                component={SelectInput}
                className="form-control"
                disabled={networkBusy}
              >
                {networkBusy ? (
                  <option>Loading...</option>
                ) : (
                  <>
                    <option defaultValue value={0}>
                      {' '}
                      Same as Site{' '}
                    </option>
                    <option value={-1}>None</option>
                    {curriculums.map((curriculum, i) => {
                      return (
                        <option key={`curriculum-${i}`} value={curriculum.id}>
                          {curriculum.id} - {curriculum.name}
                        </option>
                      );
                    })}
                  </>
                )}
              </Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={12}>
            <FormGroup>
              <Label for="exampleText" className="primary-heading">
                Notes
              </Label>
              <Field id="notes" name="notes" component="textarea" className="form-control" />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={12}>
            <FormGroup>
              <div className="flex flex-row">
                <div className="mr-2">
                  <ASCButton type="save" size="md" onClick={handleSubmit} disabled={pristine || submitting}>
                    Save
                  </ASCButton>
                </div>
                <div>
                  <ASCButton type="tertiary" size="md" onClick={() => history.goBack()}>
                    Cancel
                  </ASCButton>
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </MainCard>
    </Form>
  );
};

AddGatesForm = reduxForm({
  form: 'addGate',
})(AddGatesForm);

export default AddGatesForm;
