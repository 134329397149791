import {
  CLEAR_VISITOR_DATA,
  EDIT_VISITOR_REQUEST,
  GET_VISITORS_FAILURE,
  GET_VISITORS_REQUEST,
  GET_VISITORS_SUCCESS,
  GET_VISITOR_FAILURE,
  GET_VISITOR_REQUEST,
  GET_VISITOR_SUCCESS,
  UPDATE_VISITOR,
} from '../../actions/Visitors/Visitors';

const InitialState = {
  busy: false,
  err: false,
  res: false,
  visitors: [],
  details: {
    id: '',
    name: {
      first: '',
      last: '',
    },
    company: '',
    email: '',
    phone: '',
    notes: '',
    roles: '',
    permissions: [],
    status: '',
  },
  page: 1,
  pagination: {
    totalItems: 0,
  },
};

export default function visitorService(state = InitialState, action) {
  switch (action.type) {
    case CLEAR_VISITOR_DATA:
      return {
        ...state,
        visitors: [],
      };
    case GET_VISITORS_REQUEST:
      return {
        ...state,
        busy: true,
      };

    case GET_VISITORS_SUCCESS:
      return {
        ...state,
        res: true,
        err: false,
        busy: false,
        visitors: [...state.visitors, ...action.data],
        offset: action.data[action.data.length - 1] && action.data[action.data.length - 1].lastEvent,
        pagination: action.pagination,
        page: action.page ? action.page : 1,
      };

    case GET_VISITORS_FAILURE:
      return {
        ...state,
        res: true,
        err: true,
        busy: false,
      };
    case GET_VISITOR_REQUEST:
      return {
        ...state,
        busy: true,
        visitors: [],
      };
    case GET_VISITOR_SUCCESS:
      return {
        ...state,
        busy: false,
        err: false,
        res: true,
        details: {
          ...action.data.data,
          company: {
            ...action.data.data.company,
            network: {
              id: action.data.network.id,
            },
          },
          roles: action.data.data.roles,
          permissions: action.data.data.permissions,
        },
      };
    case GET_VISITOR_FAILURE:
      return {
        ...state,
        busy: false,
        err: true,
        res: false,
      };

    case UPDATE_VISITOR:
      return {
        ...state,
        details: {
          ...state.details,
          ...action.data,
        },
      };

    case EDIT_VISITOR_REQUEST:
      return {
        ...state,
        busy: true,
      };

    default:
      return state;
  }
}
