import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Col, FormGroup, Row } from "reactstrap";
import styled from "styled-components";
import { cancelStatusRequest, handleStatusRequest, setStatus } from "../../../actions/Status/Status";
import { editVisitor } from "../../../actions/Visitors/Visitors";
import { getRoles } from "../../../library/Utilities";

const Button = styled.button`
  margin: 0px 15px;
  color: blue;

  &:focus {
    outline: none;
  }
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  background-color: white;
  ${(p) => (p.edit ? "outline: 1px solid blue" : "outline: none")}
`;

class DetailsTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: false,
    };
  }

  handleDeactivate = (e) => {
    e.preventDefault();

    const modalConfig = {
      show: true,
      button: `Yes, block user`,
      body: `Are you sure you want to block user`,
      name: ` ${this.props.details.name.first} ${this.props.details.name.last}?`,
      action: () =>
        this.props.SetStatus(
          "visitors",
          false,
          `${this.props.match.params.id}${
            this.props.match.params.networkId ? `?networkId=${this.props.match.params.networkId}` : ""
          }`
        ),
      cancel: () => this.props.CancelStatusRequest(),
    };

    this.props.HandleStatusRequest(modalConfig);
  };

  handleActivate = (e) => {
    e.preventDefault();

    const modalConfig = {
      show: true,
      button: "Yes, un-block user",
      body: `Are you sure you want to un-block user`,
      name: ` ${this.props.details.name.first} ${this.props.details.name.last}?`,
      action: () =>
        this.props.SetStatus(
          "visitors",
          true,
          `${this.props.match.params.id}${
            this.props.match.params.networkId ? `?networkId=${this.props.match.params.networkId}` : ""
          }`
        ),
      cancel: () => this.props.CancelStatusRequest(),
    };

    this.props.HandleStatusRequest(modalConfig);
  };

  handleChange = (e) => {
    this.setState({
      text: e.target.value,
    });
  };

  handleEdit = (e) => {
    this.setState({
      edit: true,
    });

    e.preventDefault();
  };

  handleSave = (e) => {
    this.setState({
      edit: false,
    });

    const payload = {
      notes: this.state.text,
    };

    this.props.EditVisitor(payload, this.props.match.params.id, this.props.match.params.networkId);
    e.preventDefault();
  };

  render() {
    const {
      details: { status, notes, name, email, phone, roles },
    } = this.props;
    const { edit, text } = this.state;
    const role = getRoles("superadmin").find((x) => x.value === roles);

    return (
      <Card>
        <Row form>
          <Col md={role && role.value === "gateguard" ? 4 : 6}>
            <div>
              <strong>Profile:</strong>
              <br />
              <div>
                {name.first} {name.last}
                <br />
              </div>
              <div>{role ? role.label : roles}</div>
              <div>
                <a href={`tel:${phone}`}>{phone}</a>
                <br />
              </div>
              <a href={`mailto:${email}`}>{email}</a>
            </div>
          </Col>
          <Col md={role && role.value === "gateguard" ? 4 : 6}>
            <FormGroup>
              <div className="primary-heading">
                <strong>Status:</strong>
              </div>
              {status && (
                <div className={`details status ${status}`}>
                  {status === "active" ? "Active" : "Blocked"}{" "}
                  {status === "active" ? (
                    <a
                      href="#/"
                      onClick={(e) => {
                        this.handleDeactivate(e);
                      }}
                    >
                      (Block)
                    </a>
                  ) : (
                    <a
                      href="#/"
                      onClick={(e) => {
                        this.handleActivate(e);
                      }}
                    >
                      (Un-Block)
                    </a>
                  )}{" "}
                </div>
              )}
            </FormGroup>
          </Col>
          {role && role.value === "gateguard" && (
            <Col md={4}>
              <FormGroup>
                <div className="primary-heading">
                  <strong>Muster Access:</strong>
                </div>
              </FormGroup>
            </Col>
          )}
        </Row>
        <br></br>
        <div style={{ paddingBottom: "5px" }}>
          <strong>Notes</strong>
          {edit ? (
            <Button onClick={(e) => this.handleSave(e)}>&nbsp;(Save)</Button>
          ) : (
            <Button onClick={(e) => this.handleEdit(e)}>&nbsp;(Edit)</Button>
          )}
          <br />
          <Input edit={edit} disabled={!edit} value={edit ? text : notes} type="text" onChange={this.handleChange} />
        </div>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    details: state.visitorService.details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    EditVisitor: (payload, id, networkId) => dispatch(editVisitor(payload, id, networkId)),
    HandleStatusRequest: (modalConfig) => dispatch(handleStatusRequest(modalConfig)),
    CancelStatusRequest: () => dispatch(cancelStatusRequest()),
    SetStatus: (entity, status, id) => dispatch(setStatus(entity, status, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsTab);
