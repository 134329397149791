import MainCard from "@alliancesafetycouncil/asc-card";
import React from "react";
import { Col, Form, FormGroup, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import TextInput from "../../components/FormContainer/TextInput";
import { required, validPhone } from "../../library/Validation";
import ASCButton from "@alliancesafetycouncil/asc-button";

let EditProfileForm = ({ profile, handleSubmit }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <MainCard type="main">
        <Row form className="user-row-one">
          <Col md={6} className="add-col user-col-one">
            <FormGroup>
              <Field
                label="First Name"
                type="text"
                name="name.first"
                id="firstName"
                placeholder=""
                component={TextInput}
                className="form-control"
                validate={[required]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                label="Last Name"
                type="text"
                name="name.last"
                id="lastName"
                placeholder=""
                component={TextInput}
                className="form-control"
                validate={[required]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form className="user-row-four">
          <Col md={6} className="add-col">
            <FormGroup>
              <Field
                label="Phone Number"
                type="text"
                name="phone"
                id="phoneNumber"
                placeholder=""
                component={TextInput}
                className="form-control"
                validate={[required, validPhone]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={12}>
            <FormGroup>
              <div className="flex flex-row">
                <div className="mr-2">
                  <ASCButton type="save" size="md" onClick={handleSubmit}>
                    Save
                  </ASCButton>
                </div>
                <div>
                  <ASCButton type="tertiary" size="md" href="#/profile">
                    Cancel
                  </ASCButton>
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </MainCard>
    </Form>
  );
};

EditProfileForm = reduxForm({
  form: "editProfileForm",
})(EditProfileForm);

export default EditProfileForm;
