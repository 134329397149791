import React, { Component, Fragment } from 'react';

import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem, Button, Card, Col, Form, FormGroup, Input, Row } from 'reactstrap';

import { setCompaniesTotal } from '../../../actions/Companies/Companies';
import Config from '../../../config/env/config.json';

class Companies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        { headerName: 'Company', field: 'company' },
        { headerName: 'Site', field: 'site' },
        { headerName: 'Gate', field: 'gate' },
        { headerName: 'User', field: 'user' },
        { headerName: 'Role', field: 'role' },
      ],
      rowData: [
        { company: 'Data', site: 'Data', gate: 'Data', user: 'Data', role: 'Data' },
        { company: 'Data', site: 'Data', gate: 'Data', user: 'Data', role: 'Data' },
        { company: 'Data', site: 'Data', gate: 'Data', user: 'Data', role: 'Data' },
        { company: 'Data', site: 'Data', gate: 'Data', user: 'Data', role: 'Data' },
        { company: 'Data', site: 'Data', gate: 'Data', user: 'Data', role: 'Data' },
        { company: 'Data', site: 'Data', gate: 'Data', user: 'Data', role: 'Data' },
        { company: 'Data', site: 'Data', gate: 'Data', user: 'Data', role: 'Data' },
        { company: 'Data', site: 'Data', gate: 'Data', user: 'Data', role: 'Data' },
        { company: 'Data', site: 'Data', gate: 'Data', user: 'Data', role: 'Data' },
        { company: 'Data', site: 'Data', gate: 'Data', user: 'Data', role: 'Data' },
      ],
    };
  }

  componentWillMount() {
    axios
      .head(`${Config.api.endpoint}/companies`)
      .then((res) => {
        setCompaniesTotal(res.headers['x-total-count']);
      })
      .catch((err) => {
        console.log('Error fetching company.');
      });
  }

  render() {
    // const { companies } = this.props

    return (
      <Fragment>
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem tag="a" href="#/">
            Home
          </BreadcrumbItem>
        </Breadcrumb>
        <div className="page-title">
          <h3>Home</h3>
        </div>
        <div className="options">
          <Button color="primary">Add</Button> <Button color="secondary">Export</Button>{' '}
        </div>
        <Card>
          <Row>
            <Col xs="6" className="card-left">
              <Row>
                <Col>
                  <h4>9</h4>
                  <h6>Companies</h6>
                </Col>
                <Col>
                  <h4>14</h4>
                  <h6>Sites</h6>
                </Col>
                <Col>
                  <h4>3</h4>
                  <h6>Gates</h6>
                </Col>
                <Col>
                  <h4>4</h4>
                  <h6>Users</h6>
                </Col>
              </Row>
            </Col>
            <Col xs="6" className="card-right">
              <Form>
                <FormGroup>
                  <Input type="email" name="email" id="exampleEmail" placeholder="Filter by anything" />
                </FormGroup>
              </Form>
            </Col>
          </Row>
          <div className="ag-grid-wrap">
            <div className="ag-theme-balham">
              <AgGridReact columnDefs={this.state.columnDefs} rowData={this.state.rowData}></AgGridReact>
            </div>
          </div>
        </Card>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companies: state.companyService.total,
  };
};

export default connect(mapStateToProps, null)(Companies);
