import ASCButton from '@alliancesafetycouncil/asc-button';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Col, FormGroup, Row } from 'reactstrap';
import { Field } from 'redux-form';
import { verifyEntryCriteria } from '../../actions/Companies/Companies';
import { required } from '../../library/Validation';
import TextInput from './TextInput';
import ToggleSwitch from './ToggleSwitch';

class GateEntryInput extends Component {
  validate = () => {
    const { Verify, username, password } = this.props;

    const values = {
      username,
      password,
    };

    Verify(values);
  };

  render() {
    const { isEdit, entry } = this.props;
    console.log('entry:', entry);

    return (
      <Row form>
        <Col lg={6}>
          <FormGroup>
            <div className="primary-heading">iLevel Credentials</div>
            <Card>
              <Row form>
                <Col lg={6}>
                  <FormGroup>
                    <Field
                      id="ilevelUsername"
                      name="entry.iLevel.username"
                      label="Username"
                      component={TextInput}
                      type="text"
                      className="form-control"
                      validate={[required]}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col lg={6}>
                  <FormGroup>
                    <Field
                      id="password"
                      name="entry.iLevel.password"
                      component={TextInput}
                      type="password"
                      placeholder="**********"
                      label="Password"
                      className="form-control"
                      validate={isEdit ? undefined : required}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col lg={6}>
                  <FormGroup>
                    <ASCButton type="secondary" size="md" onClick={this.validate}>
                      Verify Credentials
                    </ASCButton>
                  </FormGroup>
                </Col>
              </Row>
            </Card>
          </FormGroup>
          <FormGroup>
            <div className="primary-heading">CQ Management</div>
            <Card>
              <Row form>
                <Col lg={6}>
                  <FormGroup>
                    <div>
                      <ToggleSwitch
                        id="cq-client-id"
                        name="entry.cq.client_id"
                        placeholder=""
                        type="text"
                        entry={entry}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </Card>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.companyService.busy,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    Verify: (values) => dispatch(verifyEntryCriteria(values)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GateEntryInput);
