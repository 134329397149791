import React from "react";
import MainCard from "@alliancesafetycouncil/asc-card";

const TableTotals = ({ config, className }) => {
  return (
    <div>
      <div className="flex flex-row space-x-6">
        {config.map((col) => {
          return (
            <MainCard type={col.type}>
              <div>{col.name}</div>
              <div className="text-xl">{col.total || 0}</div>
            </MainCard>
          );
        })}
      </div>
    </div>
  );
};

export default TableTotals;
