import {
  FORGOTPASSWORD_REQUEST,
  FORGOTPASSWORD_SUCCESS,
  FORGOTPASSWORD_FAILURE,
  FORGOTPASSWORD_ACK,
} from "../../actions/ForgotPassword/ForgotPassword";

const InitialState = {
  busy: false,
  error: false,
  res: false,
  msg: null,
};

export default function forgotPasswordService(state = InitialState, action) {
  switch (action.type) {
    case FORGOTPASSWORD_REQUEST:
      return {
        busy: true,
        error: false,
      };
    case FORGOTPASSWORD_SUCCESS:
      return {
        busy: false,
        error: false,
        res: true,
        msg: action.res,
      };
    case FORGOTPASSWORD_FAILURE:
      return {
        busy: false,
        error: true,
        res: false,
        msg: action.error,
      };
    case FORGOTPASSWORD_ACK:
      return InitialState;
    default:
      return state;
  }
}
