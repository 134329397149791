import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Form, FormGroup, Input, Label } from 'reactstrap';

import ASCButton from '@alliancesafetycouncil/asc-button';
import MainCard from '@alliancesafetycouncil/asc-card';
import PageTitle from '@alliancesafetycouncil/asc-page-title';

import { getAuth } from '../../../actions/Auth/Auth';
import LogoTheme from '../../../components/Logo/LogoTheme';

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      loginErrors: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit(event) {
    const { username, password } = this.state;
    const { GetAuth } = this.props;

    GetAuth(username, password);

    event.preventDefault();
  }

  render() {
    const { username, password } = this.state;
    const { auth, busy, error, role } = this.props;
    const companyId = window.user.company_id;
    const siteId = window.user.site_id;
    const networkId = window.user.network_id;

    return (
      <div className="container mt-8 sign-in md:w-1/2">
        <div className="flex justify-center">
          <div className="w-48 mb-8">
            <LogoTheme type="logo" />
          </div>
        </div>
        <Form>
          <MainCard type="main">
            <div className="mb-4 -ml-2">
              <PageTitle>Sign In</PageTitle>
            </div>
            <p className={`form-error ${error ? 'show' : ''}`}>{error}</p>
            <FormGroup>
              <Label for="exampleEmail">Email Address</Label>
              <Input
                value={username}
                onChange={this.handleChange}
                type="username"
                name="username"
                id="username"
                placeholder="Email Address"
                className="username"
              />
            </FormGroup>
            <FormGroup>
              <Label for="examplePassword">Password</Label>
              <Input
                value={password}
                onChange={this.handleChange}
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                className="password"
              />
            </FormGroup>
            <div className="mb-4">
              {busy ? (
                <div className="bg-white opacity-50">
                  <ASCButton type="primary" size="md">
                    <i class="fal fa-spinner-third fa-spin mr-2"></i>Signing In...
                  </ASCButton>
                </div>
              ) : (
                <ASCButton type="primary" size="md" onClick={this.handleSubmit}>
                  Sign In
                </ASCButton>
              )}
            </div>
            <div>
              <p>
                <a href="#/forgot-password">Forgot Password</a>
              </p>
            </div>
            <a href="https://alliancesafetycouncil.org/privacy-policy/" target="_blank">
              Privacy Policy
            </a>
          </MainCard>
        </Form>
        <div>
          {/* Auth & Redirect Handler for Superadmin */}
          {role === 'superadmin' && auth && (
            <Redirect
              to={{
                pathname: '/companies',
                state: { from: window.location },
              }}
            />
          )}
          {/* Auth & Redirect Handler for Owneradmin */}
          {role === 'owneradmin' && auth && (
            <Redirect
              to={{
                pathname: `/companies/company-details/${networkId}/${companyId}/activity-report`,
                state: { from: window.location },
              }}
            />
          )}
          {/* Auth & Redirect Handler for Siteadmin */}
          {role === 'siteadmin' && auth && (
            <Redirect
              to={{
                pathname: `/sites/site-details/${siteId}/${networkId}/activity-report`,
                state: { from: window.location },
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authService.auth,
    busy: state.authService.busy,
    error: state.authService.error,
    role: state.authService.role,
    token: state.authService.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetAuth: (username, password) => dispatch(getAuth(username, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
