import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import Sidebar from 'react-sidebar';
import { Container } from 'reactstrap';

import { SignOut } from '../../actions/Auth/Auth';
import { getCompanies } from '../../actions/Companies/Companies';
import { getGates } from '../../actions/Gates/Gates';
import { getSites } from '../../actions/Sites/Sites';
import { ackToast } from '../../actions/Toast/Toast';
import { getUsers } from '../../actions/Users/Users';
import { DeactivateModal } from '../../components/DeactivateModal';
import EmergencyEvent from '../../components/EmergencyEvent/EmergencyEvent';
import { IdleTimeout } from '../../components/IdleTimeout';
import LogoTheme from '../../components/Logo/LogoTheme';
import ToastMessage from '../../components/ToastMessage/ToastMessage';
import routes from '../../routes';
import { Sidenav } from './Sidenav';

const mql = window.matchMedia(`(min-width: 768px)`);

class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarDocked: mql.matches,

      showModal: false,
      text: {},
      action: null,
    };

    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);

    mql.addEventListener('change', this.mediaQueryChanged);
  }

  componentWillUnmount() {
    mql.removeEventListener('change', this.mediaQueryChanged);
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }

  mediaQueryChanged() {
    this.setState({ sidebarDocked: mql.matches, sidebarOpen: false });
  }

  loading = () => <div className="layout-loading"></div>;

  SignOut = () => {
    this.props.SignOut();
  };

  toggleSidebar = () => {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen,
    });
  };

  handleRedirect = (role) => {
    const companyId = window.user.company_id;
    const siteId = window.user.site_id;
    const networkId = window.user.network_id;

    const redirect = {
      siteadmin: `/sites/site-details/${siteId}/${networkId}/activity-report`,
      owneradmin: `/companies/company-details/${networkId}/${companyId}/activity-report`,
    }[role];

    return redirect || '/companies';
  };

  render() {
    const { toastConfig, modalConfig, profile } = this.props;
    const role = window.user.role;
    const { permissionsModalConfig, AckToast } = this.props;

    return (
      <div>
        <div className="app">
          <Sidebar
            sidebar={<Sidenav SignOut={this.SignOut} Profile={profile} />}
            open={this.state.sidebarOpen}
            docked={this.state.sidebarDocked}
            onSetOpen={this.onSetSidebarOpen}
            sidebarDocked={this.props.sidebarDocked}
            shadow={false}
          >
            <div className="fixed flex items-center w-full px-1 pl-12 bg-blue-900 md:hidden">
              <div
                onClick={this.toggleSidebar}
                className="absolute left-0 p-2 ml-2 text-white align-middle curser-pointer"
              >
                <i className="fas fa-bars"></i>
              </div>
              <div className="w-24 py-3">
                <LogoTheme type="logoLight" />
              </div>
            </div>
            <IdleTimeout action={this.SignOut} />
            <div className="app-body">
              <main className="main">
                <div className="flex flex-col w-full h-screen px-5 py-0 mt-12 md:mt-0">
                  {/* <Suspense fallback={this.loading()}> */}
                  <Switch>
                    {routes(role).map((route, idx) => {
                      return route.component ? (
                        <Route
                          key={idx}
                          path={route.path}
                          exact={route.exact}
                          name={route.name}
                          render={(props) => <route.component {...props} toggleModal={this.toggleModal} />}
                        />
                      ) : null;
                    })}
                    <Route path="" render={() => <Redirect to={this.handleRedirect(role)} />} />
                  </Switch>
                  {/* </Suspense> */}
                  {/* Emergency Event Tag */}
                  <Container>
                    {(role === 'siteadmin' || role === 'owneradmin') && <EmergencyEvent {...this.props} />}
                  </Container>
                </div>
                <ToastMessage toastConfig={toastConfig} ack={AckToast} />
                <DeactivateModal modalConfig={modalConfig} />
                <DeactivateModal modalConfig={permissionsModalConfig} />
              </main>
            </div>
            {/* <Footer/> */}
          </Sidebar>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modalConfig: state.statusService.modalConfig,
    permissionsModalConfig: state.userService.modalConfig,
    toastConfig: state.toastService,
    profile: state.authService,
    role: state.authService.role,
    companyDetails: state.companyService.details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    AckToast: () => dispatch(ackToast()),
    SignOut: () => dispatch(SignOut()),
    GetCompanies: () => dispatch(getCompanies('asc', 100, 0, 'name')),
    GetSites: () => dispatch(getSites('asc', 100, 0, 'id')),
    GetGates: () => dispatch(getGates('asc', 100, 0, 'id')),
    GetUsers: () => dispatch(getUsers('asc', 100, 0, 'id')),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
