import axios from 'axios';

import Config from '../../config/env/config.json';
import { setToastFailure, setToastSuccess } from '../Toast/Toast';

export function getCompanies(direction, limit, offset, sort) {
  return async (dispatch) => {
    dispatch(getCompaniesRequest());
    return axios
      .get(
        `${Config.api.endpoint}/companies?sortDirection=${direction}&limit=${limit}&offset=${offset}&sortColumn=${sort}`
      )
      .then((res) => {
        dispatch(getCompaniesSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getCompaniesFailure('Could not fetch.'));
      });
  };
}

export function getCompany(id) {
  return async (dispatch) => {
    dispatch(getCompanyRequest);
    return axios
      .get(`${Config.api.endpoint}/companies/${id}`)
      .then((res) => {
        dispatch(getCompanySuccess(res.data));
      })
      .catch((err) => {
        dispatch(getCompanyFailure(err));
      });
  };
}

export function verifyEntryCriteria(payload) {
  return async (dispatch) => {
    dispatch(verifyEntryCriteriaRequest());
    return axios
      .post(`${Config.api.endpoint}/companies/ilevel/verify`, payload)
      .then((res) => {
        dispatch(verifyEntryCriteriaSuccess(res.data));
        if (res.data.data.valid) {
          dispatch(setToastSuccess());
        } else {
          dispatch(setToastFailure({ err: 'Invalid Credentials' }));
        }
      })
      .catch((err) => {
        dispatch(verifyEntryCriteriaFailure(err));
        dispatch(setToastFailure({ err: 'Invalid Credentials' }));
      });
  };
}

export const VERIFY_ENTRY_CRITERIA_REQUEST = 'VERIFY_ENTRY_CRITERIA_REQUEST';
function verifyEntryCriteriaRequest() {
  return {
    type: VERIFY_ENTRY_CRITERIA_REQUEST,
  };
}

export const VERIFY_ENTRY_CRITERIA_SUCCESS = 'VERIFY_ENTRY_CRITERIA_SUCCESS';
function verifyEntryCriteriaSuccess() {
  return {
    type: VERIFY_ENTRY_CRITERIA_SUCCESS,
  };
}

export const VERIFY_ENTRY_CRITERIA_FAILURE = 'VERIFY_ENTRY_CRITERIA_FAILURE';
function verifyEntryCriteriaFailure(error) {
  return {
    type: VERIFY_ENTRY_CRITERIA_FAILURE,
    error,
  };
}

export const GET_COMPANIES_REQUEST = 'GET_COMPANIES_REQUEST';
function getCompaniesRequest() {
  return {
    type: GET_COMPANIES_REQUEST,
  };
}

export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
function getCompaniesSuccess(data) {
  return {
    type: GET_COMPANIES_SUCCESS,
    data,
  };
}

export const GET_COMPANIES_FAILURE = 'GET_COMPANIES_FAILURE';
function getCompaniesFailure(error) {
  return {
    type: GET_COMPANIES_FAILURE,
    error,
  };
}

export const SET_COMPANIES_TOTAL = 'SET_COMPANIES_TOTAL';
export function setCompaniesTotal(data) {
  return {
    type: SET_COMPANIES_TOTAL,
    data,
  };
}

export const GET_COMPANY_REQUEST = 'GET_COMPANY_REQUEST';
function getCompanyRequest() {
  return {
    type: GET_COMPANY_REQUEST,
  };
}

export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
function getCompanySuccess(data) {
  return {
    type: GET_COMPANY_SUCCESS,
    data,
  };
}

export const GET_COMPANY_FAILURE = 'GET_COMPANY_FAILURE';
function getCompanyFailure(err) {
  return {
    type: GET_COMPANY_FAILURE,
    err,
  };
}

export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export function updateCompany(data) {
  return {
    type: UPDATE_COMPANY,
    data,
  };
}
