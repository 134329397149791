import {
  CANCEL_PERMISSIONS_REQUEST,
  GET_USERS_FAILURE,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  HANDLE_PERMISSIONS_REQUEST,
  SET_PERMISSIONS_FAILURE,
  SET_PERMISSIONS_REQUEST,
  SET_PERMISSIONS_SUCCESS,
  SET_USERS_TOTAL,
  UPDATE_USER,
} from '../../actions/Users/Users';

const InitialState = {
  busy: false,
  res: false,
  error: false,
  details: {
    id: '',
    name: {
      first: '',
      last: '',
    },
    company: '',
    email: '',
    phone: '',
    notes: '',
    roles: '',
    permissions: [],
    status: '',
    password: '**********',
  },
  data: [],
  total: 0,
  modalConfig: {},
};

export default function userService(state = InitialState, action) {
  switch (action.type) {
    case GET_USERS_REQUEST:
      return {
        ...state,
        busy: true,
        res: false,
        data: [],
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        busy: false,
        res: true,
        data: action.data.data.users,
        total: action.data.data.users.length,
      };
    case GET_USERS_FAILURE:
      return {
        ...state,
        busy: false,
        res: false,
        error: action.error,
      };
    case GET_USER_REQUEST:
      return {
        ...state,
        busy: true,
      };
    case GET_USER_SUCCESS:
      let permissions = {};

      if (action.data.data.permissions.includes('full:muster')) {
        permissions.fullMuster = true;
      }

      if (action.data.data.roles === 'superadmin') {
        return {
          ...state,
          busy: false,
          err: false,
          res: true,
          details: {
            ...action.data.data,
            roles: action.data.data.roles,
            ...permissions,
          },
        };
      }

      return {
        ...state,
        busy: false,
        err: false,
        res: true,
        details: {
          ...action.data.data,
          company: {
            ...action.data.data.company,
            network: {
              id: action.data.network.id,
            },
          },
          roles: action.data.data.roles,
          ...permissions,
        },
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        busy: false,
        err: true,
        res: false,
      };
    case UPDATE_USER:
      return {
        ...state,
        details: {
          ...state.details,
          ...action.data,
        },
      };
    case SET_USERS_TOTAL:
      return {
        ...state,
        total: action.data,
      };
    case SET_PERMISSIONS_REQUEST:
      return {
        ...state,
        busy: true,
        modalConfig: {
          show: false,
        },
      };
    case SET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        busy: false,
        err: false,
        res: true,
      };
    case SET_PERMISSIONS_FAILURE:
      return {
        ...state,
        busy: false,
        err: true,
        res: false,
        modalConfig: {
          show: false,
        },
      };
    case HANDLE_PERMISSIONS_REQUEST:
      return {
        ...state,
        modalConfig: action.payload,
      };
    case CANCEL_PERMISSIONS_REQUEST:
      return {
        ...state,
        modalConfig: {
          show: false,
        },
      };
    default:
      return state;
  }
}
