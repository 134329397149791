import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { getProfile } from '../../actions/Profile/Profile';
import DetailsTab from './DetailsTab';

class Profile extends Component {
  constructor(props) {
    super(props);

    const { GetProfile } = props;

    GetProfile();
  }

  render() {
    const { profile } = this.props;

    return (
      <div className="flex flex-1 flex-col">
        <Switch>
          <Route path={`/profile`} exact={true} name="Details" render={() => <DetailsTab profile={profile} />} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.profileService.busy,
    res: state.profileService.res,
    err: state.profileService.err,
    profile: state.profileService.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetProfile: () => dispatch(getProfile()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
