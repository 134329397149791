import {
  SET_STATUS_REQUEST,
  SET_STATUS_SUCCESS,
  SET_STATUS_FAILURE,
  HANDLE_STATUS_REQUEST,
  CANCEL_STATUS_REQUEST,
  ACK_TOAST,
} from "../../actions/Status/Status";

const InitialState = {
  busy: false,
  err: false,
  res: false,
  modalConfig: {},
};

export default function statusService(state = InitialState, action) {
  switch (action.type) {
    case SET_STATUS_REQUEST:
      return {
        ...state,
        busy: true,
        modalConfig: {
          show: false,
        },
      };
    case SET_STATUS_SUCCESS:
      return {
        ...state,
        busy: false,
        err: false,
        res: true,
      };
    case SET_STATUS_FAILURE:
      return {
        ...state,
        busy: false,
        err: true,
        res: false,
        modalConfig: {
          show: false,
        },
      };
    case HANDLE_STATUS_REQUEST:
      return {
        ...state,
        modalConfig: action.payload,
      };
    case CANCEL_STATUS_REQUEST:
      return {
        ...state,
        modalConfig: {
          show: false,
        },
      };
    case ACK_TOAST:
      return {
        ...InitialState,
      };
    default:
      return state;
  }
}
