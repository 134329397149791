import PageTitle from "@alliancesafetycouncil/asc-page-title";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { clearSites, getSite, getSitesByNetwork } from "../../../actions/Sites/Sites";
import FormContainer from "../../../components/FormContainer/FormContainer";
import AddMusterLocationForm from "./AddMusterLocationForm";

class AddMusterLocation extends Component {
  componentWillMount() {
    const { GetSitesByNetwork, ClearSites, GetSite, match } = this.props;
    const { networkId, id } = match.params;

    if (networkId) {
      GetSitesByNetwork(networkId);
    } else {
      ClearSites();
    }

    GetSite(networkId, id);
  }

  render() {
    const { details, dataSite: sites, locationId } = this.props;
    const { networkId, id } = this.props.match.params;

    const { name } = details;

    const data = {
      site: {
        id: Number(id),
      },
    };

    return (
      <div className="flex flex-1 flex-col">
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem tag="a" href={`/#/sites/site-details/${id}/${networkId}/activity-report`}>
            {name}
          </BreadcrumbItem>
          <BreadcrumbItem>Add Muster Location</BreadcrumbItem>
        </Breadcrumb>
        <PageTitle icon="gate">Add Muster Location</PageTitle>
        <FormContainer
          {...this.props}
          entity="locations"
          action="add"
          redirect={`/muster-location/location-details/${locationId}/${networkId}/details`}
          requiresNetworkId={true}
          initialValues={data}
          sites={sites}
          status={undefined}
          Form={AddMusterLocationForm}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locationId: state.formService.id,
    busy: state.formService.busy,
    res: state.siteService.res,
    err: state.siteService.err,
    dataSite: state.siteService.data,
    details: state.siteService.details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ClearSites: () => dispatch(clearSites()),
    GetSite: (networkId, siteId) => dispatch(getSite(networkId, siteId)),
    GetSitesByNetwork: (networkId) => dispatch(getSitesByNetwork(networkId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMusterLocation);
