import ASCButton from '@alliancesafetycouncil/asc-button';
import MainCard from '@alliancesafetycouncil/asc-card';
import React, { useEffect, useState } from 'react';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import SelectInput from '../../../components/FormContainer/SelectInput';
import TextInput from '../../../components/FormContainer/TextInput';
import ToggleSwitchGate from '../../../components/FormContainer/ToggleSwitchGate';
import { required, validEmail, validPhone } from '../../../library/Validation';

let EditGateForm = ({
  history,
  handleSubmit,
  companies,
  initialValues,
  curriculums,
  networkBusy,
  data,
  entry,
  default_value = false,
  checked,
}) => {
  const [enabled, setEnabled] = useState(default_value);
  // console.log('data:', data)
  // console.log('InitialValues2:',initialValues )

  useEffect(() => {
    if (entry && entry.cq && entry.cq.enabled !== undefined) {
      setEnabled(entry.cq.enabled);
    }
  }, [entry]);

  const handleCheckboxChange = () => {
    setEnabled(!enabled);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <MainCard type="main">
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="text"
                name="name"
                id="name"
                label="Gate Name"
                placeholder=""
                component={TextInput}
                className="form-control"
                validate={[required]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                id="company"
                name="company.network.id"
                component={SelectInput}
                className="form-control"
                label="Company"
                validate={[required]}
                disabled={true}
              >
                <option value={''}>Loading...</option>
                {companies &&
                  companies.map((company) => {
                    return (
                      <option key={company.network.id} value={company.network.id}>
                        {company.name}
                      </option>
                    );
                  })}
              </Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Label for="status" className="primary-heading">
                Enable/Disable CQ
              </Label>
              {/* <Row form>
                <Col lg={1}>
                  <GateEntryCQCheck entry={entry} />
                </Col>
              </Row> */}
              {/* <Row form>
                <Col lg={1}>
                  <Field
                    id="cq-client-enabled"
                    name="entry.cq.enabled"
                    className="form-control-input"
                    label="Enable/Disable CQ"
                    component="input"
                    type="checkbox"
                    checked={data.details.config.entry.cq.enabled}
                    onChange={handleCheckboxChange}
                    />
                </Col>
              </Row> */}
              <ToggleSwitchGate entry={entry} initialValues={initialValues} checked={checked} />
            </FormGroup>
            <FormGroup>
              <Field
                name="site.id"
                id="site"
                placeholder=""
                label="Site"
                component={SelectInput}
                validate={required}
                className="form-control"
                disabled={true}
              >
                <option value={''}>Loading...</option>
                {
                  <option key={initialValues.site.id} value={initialValues.site.id}>
                    {initialValues.site.name}
                  </option>
                }
              </Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="text"
                name="contact.name"
                id="gateContactName"
                label="Gate Contact (Name)"
                placeholder=""
                component={TextInput}
                className="form-control"
                required={false}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="text"
                name="contact.phone"
                id="contactPhone"
                label="Contact Phone"
                placeholder=""
                component={TextInput}
                className="form-control"
                validate={[validPhone]}
                required={false}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="email"
                name="contact.email"
                component={TextInput}
                label="Contact Email Address"
                id="email"
                placeholder=""
                className="form-control"
                validate={[validEmail]}
                required={false}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                id="ilevelCurriculum"
                name="config.entry.iLevel.curriculum"
                label="iLevel Curriculum"
                component={SelectInput}
                className="form-control"
                disabled={networkBusy}
              >
                {networkBusy ? (
                  <option>Loading...</option>
                ) : (
                  <>
                    <option defaultValue value={0}>
                      {' '}
                      Same as Site{' '}
                    </option>
                    <option value={-1}>None</option>
                    {curriculums.map((curriculum, i) => {
                      return (
                        <option key={`curriculum-${i}`} value={curriculum.id}>
                          {curriculum.id} - {curriculum.name}
                        </option>
                      );
                    })}
                  </>
                )}
              </Field>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={12}>
            <FormGroup>
              <Label for="exampleText" className="primary-heading">
                Notes
              </Label>
              <Field id="notes" name="notes" component="textarea" className="form-control" />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={12}>
            <FormGroup>
              <div className="flex flex-row">
                <div className="mr-2">
                  <ASCButton type="save" size="md" onClick={handleSubmit}>
                    Save
                  </ASCButton>
                </div>
                <div>
                  <ASCButton type="tertiary" size="md" onClick={() => history.goBack()}>
                    Cancel
                  </ASCButton>
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </MainCard>
    </Form>
  );
};

EditGateForm = reduxForm({
  form: 'editGate',
  enableReinitialize: true,
})(EditGateForm);

// EditGateForm = connect(state => {
//   const cq = selector(state, 'entry.cq.enabled')
//   return {
// const selector = formValueSelector('editGate')
//     cq
//   }
// })(EditGateForm)

export default EditGateForm;
