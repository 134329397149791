import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ConfigureProfile, Verify, VerifyFailure } from './actions/Auth/Auth';
import './App.scss';
import Layout from './containers/Layout';
import ForgotPassword from './views/ForgotPassword';
import SignIn from './views/Pages/SignIn';

// simple component to call route render when authed, otherwise return redirect.
const PrivateRoute = ({ auth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth === true) {
          return rest.render();
        }

        if (auth === false) {
          return (
            <Redirect
              to={{
                pathname: '/sign-in',
                state: { from: rest.location },
              }}
            />
          );
        }
      }}
    />
  );
};

// Loading
const loading = () => (
  <div>
    <span>Loading...</span>
  </div>
);

class App extends Component {
  checkAuth = () => {
    const { Verify, VerifyFailure, ConfigureProfile } = this.props;

    const token = localStorage.getItem('access_token');
    const role = window.user.role;

    if (token) {
      Verify(token);
    } else {
      VerifyFailure();
    }

    if (role) {
      ConfigureProfile({
        role,
      });
    }
  };

  // soft verify if there is a token in sessionStorage
  componentWillMount() {
    this.checkAuth();
  }

  render() {
    const { auth } = this.props;

    return (
      <HashRouter>
        <Switch>
          <Route path="/sign-in" name="SignIn Page" render={(props) => <SignIn {...props} />} />
          <Route
            path="/forgot-password"
            name="Forgot Password Page"
            render={(props) => <ForgotPassword {...props} />}
          />
          <PrivateRoute auth={auth} path="/" name="Layout" render={(props) => <Layout />} />
        </Switch>
      </HashRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authService.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    Verify: (token) => dispatch(Verify(token)),
    VerifyFailure: () => dispatch(VerifyFailure()),
    ConfigureProfile: (data) => dispatch(ConfigureProfile(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
