import ASCButton from '@alliancesafetycouncil/asc-button';
import MainCard from '@alliancesafetycouncil/asc-card';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getActiveEmergencies } from '../../actions/Sites/Sites';

const EmergencyEventCard = ({ event }) => (
  <MainCard type="alert">
    <div className="whitespace-no-wrap">
      <i className="fal fa-exclamation-triangle text-xl"></i>
      <span className="ml-2 mr-6">
        {event.name}
        <span className="italic"> (In Progess...)</span>
      </span>
      <ASCButton
        type="alert"
        size="sm"
        href={`#/emergency/emergency-details/${event.id}/${event.network}/muster-report`}
      >
        View
      </ASCButton>
    </div>
  </MainCard>
);

const EmergencyEventContainer = ({ events }) => (
  <div className="flex justify-end fixed right-0 top-0 pt-6 mr-6 space-x-4">
    {events.map((event, index) => (
      <EmergencyEventCard key={`event-list-${index}`} event={event} />
    ))}
  </div>
);

class EmergencyEvent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timer: null,
    };
  }

  refreshTimer = () => {
    const { failures } = this.props;

    clearTimeout(this.state.timer);

    if (failures > 10) return;
    this.createTimer();
  };

  fetchEvents = () => {
    this.props.FetchEvents();
    this.refreshTimer();
  };

  createTimer = () => {
    this.setState({
      timer: setTimeout(this.fetchEvents, 10000),
    });
  };

  componentDidMount() {
    this.createTimer();
    this.props.FetchEvents();
  }

  componentWillUnmount() {
    clearTimeout(this.state.timer);
  }

  render() {
    const { events } = this.props;

    return <EmergencyEventContainer events={events} />;
  }
}

const mapStateToProps = (state) => {
  return {
    events: state.siteService.activeEmergencies,
    failures: state.siteService.failures,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    FetchEvents: () => dispatch(getActiveEmergencies()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmergencyEvent);
