import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { Field } from 'redux-form';
import styled from 'styled-components';
import TextInput from './TextInput';

const CheckBoxWrapper = styled.div`
  position: relative;
`;

const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: #85ba30;
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

const ToggleSwitch = ({ entry, label, type, name, id, placeholder, required = true, default_value = false }) => {
  const [enabled, setEnabled] = useState(default_value);

  useEffect(() => {
    if (entry && entry.cq && entry.cq.enabled !== undefined) {
      setEnabled(entry.cq.enabled);
    }
  }, [entry]);

  const handleCheckboxChange = () => {
    setEnabled(!enabled);
  };

  return (
    <div className="form-group">
      <div>
        <Col lg={6}>
          <FormGroup>
            <Label for="status" className="primary-heading">
              Enable/Disable CQ
            </Label>
            <Row form>
              <Col lg={1}>
                <Field
                  id="cq-client-enabled"
                  name="entry.cq.enabled"
                  className="form-control-input"
                  label="Enable/Disable CQ"
                  component="input"
                  type="checkbox"
                  onChange={handleCheckboxChange}
                  checked={enabled}
                  defaultChecked={enabled}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </div>
      <br></br>
      <div>
        <Field
          id={id}
          name={name}
          component={TextInput}
          label="Client ID"
          placeholder={placeholder || label}
          type={type}
          className="form-control"
          disabled={!enabled}
        />
      </div>
    </div>
  );
};

export default ToggleSwitch;
