import {
  GET_CURRICULUMS_REQUEST,
  GET_CURRICULUMS_SUCCESS,
  GET_CURRICULUMS_FAILURE,
  CLEAR_CURRICULUMS,
} from "../../actions/Networks/Networks";

const InitialState = {
  busy: false,
  res: false,
  error: false,
  curriculums: [],
  total: 0,
};

export default function networkService(state = InitialState, action) {
  switch (action.type) {
    case GET_CURRICULUMS_REQUEST:
      return {
        ...state,
        busy: true,
        res: false,
      };
    case GET_CURRICULUMS_SUCCESS:
      return {
        ...state,
        busy: false,
        res: true,
        curriculums: action.data.data.iLevel.curriculums,
      };
    case GET_CURRICULUMS_FAILURE:
      return {
        ...state,
        busy: false,
        res: false,
        error: action.error,
      };
    case CLEAR_CURRICULUMS:
      return {
        ...state,
        busy: false,
        res: true,
        curriculums: [],
      };
    default:
      return state;
  }
}
