import DateFnsUtils from '@date-io/date-fns';
import { createMuiTheme } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import React from 'react';

const defaultMaterialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: 'red',
      },
    },
    MuiPickersDay: {
      day: {
        color: 'black',
      },
      daySelected: {
        backgroundColor: 'black',
      },
      dayDisabled: {
        color: 'black',
      },
      current: {
        color: 'black',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: 'black',
      },
    },
  },
});

export const DateFilter = (props) => {
  const { startDate, endDate, handleDate } = props;

  const adjustTime = (value, h, m, s) => {
    value.setHours(h);
    value.setMinutes(m);
    value.setSeconds(s);

    return value;
  };

  const parseDate = (date, type) => {
    if (!date) return handleDate(date, type);

    if (type === 'startDate') {
      handleDate(adjustTime(date, '00', '00', '00'), type);
    }

    if (type === 'endDate') {
      handleDate(adjustTime(date, '23', '59', '59'), type);
    }
  };

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div style={{ margin: '0px 5px 0px 10px' }}>
          <DatePicker
            clearable
            disableToolbar
            inputVariant="outlined"
            format="MM/dd/yyyy"
            name="startDate"
            placeholder="Start Date"
            value={startDate}
            onChange={(value) => {
              parseDate(value, 'startDate');
            }}
          />
        </div>
        <div className="vl hidden"></div>
        <div style={{ margin: '0px 10px 0px 5px' }}>
          <DatePicker
            clearable
            disableToolbar
            inputVariant="outlined"
            format="MM/dd/yyyy"
            name="endDate"
            placeholder="End Date"
            value={endDate}
            onChange={(value) => {
              parseDate(value, 'endDate');
            }}
          />
        </div>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};
