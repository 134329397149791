import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Col, FormGroup, Row } from 'reactstrap';
import { cancelStatusRequest, handleStatusRequest, setStatus } from '../../../actions/Status/Status';

class DetailsTab extends Component {
  handleDeactivate = (e) => {
    e.preventDefault();

    const modalConfig = {
      show: true,
      button: 'Yes, deactivate',
      body: `Are you sure you want to deactivate`,
      name: ` ${this.props.details.name}?`,
      action: () => this.props.SetStatus('companies', false, this.props.match.params.id),
      cancel: () => this.props.CancelStatusRequest(),
    };

    this.props.HandleStatusRequest(modalConfig);
  };

  handleActivate = (e) => {
    e.preventDefault();

    const modalConfig = {
      show: true,
      button: 'Yes, activate',
      body: `Are you sure you want to activate`,
      name: ` ${this.props.details.name}?`,
      action: () => this.props.SetStatus('companies', true, this.props.match.params.id),
      cancel: () => this.props.CancelStatusRequest(),
    };

    this.props.HandleStatusRequest(modalConfig);
  };

  render() {
    const { contact, status, notes, config, hasMusterFeature, hasBlockFeature, hasGuardOverride } = this.props.details;

    return (
      <Card>
        <Row form>
          <Col md={4}>
            <div>
              <strong>Contact:</strong>
              <br />
              <div>
                {contact.name}
                <br />
              </div>
              <div>
                <a href={`tel:${contact.phone}`}>{contact.phone}</a>
                <br />
              </div>
              <a href={`mailto:${contact.email}`}>{contact.email}</a>
            </div>
          </Col>
          <Col md={4}>
            <div>
              <strong>Has Emergency/Muster Access:</strong>
              <br />
              {hasMusterFeature ? 'Yes' : 'No'}
            </div>
            <br />
            <div>
              <strong>Has Block Access:</strong>
              <br />
              {hasBlockFeature ? 'Yes' : 'No'}
            </div>
            <br />
            {/* <div>
              <strong>Has Guard Override Access:</strong>
              <br />
              {hasGuardOverride ? 'Yes' : 'No'}
            </div> */}
          </Col>
          <Col md={4}>
            <FormGroup>
              <div className="primary-heading">
                <strong>Status:</strong>
              </div>
              {status && (
                <div className={`details status ${status}`}>
                  {status}{' '}
                  {status === 'active' ? (
                    <a
                      href="#/"
                      onClick={(e) => {
                        this.handleDeactivate(e);
                      }}
                    >
                      (Deactivate)
                    </a>
                  ) : (
                    <a
                      href="#/"
                      onClick={(e) => {
                        this.handleActivate(e);
                      }}
                    >
                      (Activate)
                    </a>
                  )}{' '}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <br></br>
        <p>
          <strong>Notes</strong>
          <br />
          {notes}
        </p>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    details: state.companyService.details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    HandleStatusRequest: (modalConfig) => dispatch(handleStatusRequest(modalConfig)),
    CancelStatusRequest: () => dispatch(cancelStatusRequest()),
    SetStatus: (entity, status, id) => dispatch(setStatus(entity, status, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsTab);
