import React from 'react';

const SelectInput = ({
  options,
  input,
  label,
  name,
  id,
  children,
  required = true,
  disabled = false,
  meta: { touched, error, warning },
}) => (
  <div key={`${name}-${id}`} className="form-group">
    <label htmlFor={name} className="primary-heading">
      {required && <span className={`form-required ${touched && error && 'active'}`}>*&nbsp;</span>}
      {label}
    </label>
    <div>
      <select id={id} name={name} {...input} className="form-control" disabled={disabled}>
        {children}
      </select>
      {touched &&
        ((error && <span className="form-error">{error}</span>) ||
          (warning && <span className="form-warning">{warning}</span>))}
    </div>
  </div>
);

export default SelectInput;
