import Card from '@alliancesafetycouncil/asc-card';
import PageTitle from '@alliancesafetycouncil/asc-page-title';
import Table from '@alliancesafetycouncil/asc-table';
import { AllModules } from 'ag-grid-enterprise';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getVisitors } from '../../../actions/Visitors/Visitors';
import { TitleBumper } from '../../../components/common';

class Visitors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      params: {
        direction: 'asc',
        limit: 100,
        offset: 0,
        sort: 'id',
      },
      filter: '',
      current: {},
      modules: AllModules,
      columnDefs: [
        {
          headerName: 'Visitor',
          field: 'studentName',
          cellRenderer: 'renderName',
        },
        {
          headerName: 'Company',
          field: 'companyName',
          cellRenderer: 'renderCompany',
        },
        {
          headerName: 'Compliance',
          field: 'compliant',
          cellRenderer: 'renderCompliance',
        },
        {
          headerName: 'Site',
          field: 'siteName',
          cellRenderer: 'renderSite',
        },
        { headerName: 'ID', field: 'studentId' },
        {
          headerName: 'Status',
          field: 'status',
          filter: 'agSetColumnFilter',
          cellRenderer: 'renderStatus',
          valueFormatter: (params) => {
            let value = params.value;

            if (typeof value === 'string') {
              value = value === 'inactive' ? 'Blocked' : value;
              return value.charAt(0).toUpperCase() + value.slice(1, value.length);
            }

            return value;
          },
        },
      ],
      defaultColDef: {
        sortable: true,
        flex: 1,
        minWidth: 100,
        filter: true,
      },
      customCells: {
        renderCompliance: (row) => {
          const color = row.data.compliant ? 'green' : 'red';
          return row.data && <span style={{ color: color }}>{row.data.compliant ? 'Compliant' : 'Non-Compliant'}</span>;
        },
        renderStatus: (row) => {
          const color = row.valueFormatted === 'Active' ? 'green' : 'red';
          return row.data && <span style={{ color: color }}>{row.valueFormatted}</span>;
        },
        renderSite: (row) => {
          return (
            row.data && (
              <Link to={`/sites/site-details/${row.data.siteId}/${row.data.networkId}/details`}>
                {row.data.siteName}
              </Link>
            )
          );
        },
        renderCompany: (row) => {
          return (
            row.data && (
              <Link to={`/companies/company-details/${row.data.networkId}/${row.data.companyId}/details`}>
                {row.data.companyName}
              </Link>
            )
          );
        },
        renderName: (row) => {
          return (
            row.data && (
              <Link to={`/visitors/visitors-details/${row.data.id}/${row.data.networkId}/details`}>
                {row.data.studentName}
              </Link>
            )
          );
        },
      },
      config: [],
    };
  }

  componentDidMount() {
    this.props.GetVisitors();
  }

  render() {
    return (
      <Fragment>
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem active> All Visitors</BreadcrumbItem>
        </Breadcrumb>
        <TitleBumper>
          <PageTitle icon="user">All Visitors</PageTitle>
        </TitleBumper>
        <Card type="main">
          <Table
            {...this.props}
            className="ag-theme-balham flex flex-col relative text-sm"
            columnDefs={this.state.columnDefs}
            getData={(offset, paginate, page) => this.props.GetVisitors(offset, paginate, page)}
            data={this.props.data}
            headerConfig={this.state.config}
            customCells={this.state.customCells}
            defaultColDef={this.state.defaultColDef}
            filter_type="any"
            getGridApi={this.getGridApi}
            defaultFilter={{ column: 'status', value: 'active' }}
            rowHeight={50}
            enablePagination={false}
            pageSize={1000}
            totalItems={this.props.pagination.totalItems}
          />
        </Card>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.visitorService.busy,
    res: state.visitorService.res,
    err: state.visitorService.err,
    data: state.visitorService.visitors,
    pagination: state.visitorService.pagination,
    page: state.visitorService.page,
    offset: state.visitorService.offset,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetVisitors: (offset, paginate, page) => dispatch(getVisitors(offset, paginate, page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Visitors);
