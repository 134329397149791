import PageTitle from '@alliancesafetycouncil/asc-page-title';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getCompanyActivity } from '../../../actions/Activity/Activity';
import { getCompany } from '../../../actions/Companies/Companies';
import { getGatesByNetwork } from '../../../actions/Gates/Gates';
import { getSitesByNetwork } from '../../../actions/Sites/Sites';
import { getUsersByNetwork } from '../../../actions/Users/Users';
import { TitleBumper } from '../../../components/common';
import TabDisplay from '../../../components/TabDisplay/TabDisplay';
import ActivityTab from './ActivityTab';
import DetailsTab from './DetailsTab';
import GatesTab from './GatesTab';
import SitesTab from './SitesTab';
import UsersTab from './UsersTab';

class CompanyDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      params: {
        direction: 'asc',
        limit: 100,
        offset: 0,
        sort: 'name',
      },
      config: [
        {
          name: 'Activity Report',
          path: '/activity-report',
          component: ActivityTab,
          default: true,
          exact: true,
        },
        {
          name: 'Sites',
          path: '/sites',
          component: SitesTab,
          exact: true,
        },
        {
          name: 'Gates',
          label: 'Gate',
          path: '/gates',
          component: GatesTab,
          exact: true,
        },
        {
          name: 'Users',
          label: 'User',
          path: '/users',
          component: UsersTab,
          exact: true,
        },
        {
          name: 'Details',
          path: '/details',
          component: DetailsTab,
          exact: true,
          showEdit: true,
        },
      ],
    };
  }

  componentWillMount() {
    this.props.GetCompany(this.props.match.params.id);
  }

  render() {
    const { name } = this.props.details;
    const { id, networkId } = this.props.match.params;

    const role = window.user.role;

    return (
      <div className="flex flex-1 flex-col">
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem tag="a" href="#/companies">
            {name}
          </BreadcrumbItem>
        </Breadcrumb>
        {role === 'superadmin' ? (
          <TitleBumper>
            <PageTitle icon="company" editLink={`#/companies/edit-company/${networkId}/${id}`}>
              {name}
            </PageTitle>
          </TitleBumper>
        ) : (
          <PageTitle icon="company">{name}</PageTitle>
        )}
        <TabDisplay
          {...this.props}
          tabs={this.state.config}
          edit={{
            path: `#/companies/edit-company/${networkId}/${id}`,
            label: 'Edit Company',
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    details: state.companyService.details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetCompany: (id) => dispatch(getCompany(id)),
    GetActivityByNetwork: (networkId, offset) => dispatch(getCompanyActivity(networkId, offset)),
    GetGatesByNetwork: (networkId) => dispatch(getGatesByNetwork(networkId)),
    GetSitesByNetwork: (networkId) => dispatch(getSitesByNetwork(networkId)),
    GetUsersByNetwork: (networkId) => dispatch(getUsersByNetwork(networkId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);
