import Moment from "moment-timezone";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Col, Label, Row } from "reactstrap";
import { cancelStatusRequest, endEmergency, handleStatusRequest } from "../../../actions/Status/Status";
import { relativeFormats } from "../../../library/Utilities";

const EmergencyStatus = ({ handleEndEmergency }) => (
  <div className="flex justify-left items-center">
    <p className="bg-orange-500 py-1 px-2 rounded-lg mr-2 text-white">In progress</p>
    <a className="" href="#/" onClick={handleEndEmergency}>
      (End Emergency Now)
    </a>
  </div>
);

class Details extends Component {
  handleEndEmergency = (e) => {
    e.preventDefault();

    const payload = {
      end_date: new Date().toISOString(),
    };

    const modalConfig = {
      show: true,
      button: "Yes, end emergency",
      body: `Are you sure you want to end the current emergency?`,
      name: ` ${this.props.details.name}?`,
      action: () =>
        this.props.EndEmergency(
          "emergencies",
          payload,
          `${this.props.match.params.id}?networkId=${this.props.match.params.networkId}`
        ),
      cancel: () => this.props.CancelStatusRequest(),
    };

    this.props.HandleStatusRequest(modalConfig);
  };

  getValues = (value, timezone) => {
    if (value && timezone) {
      return {
        date: Moment(value).tz(timezone).calendar(null, relativeFormats()),
        time: Moment(value).tz(timezone).format("h:mm A z"),
      };
    }

    return {
      date: null,
      time: null,
    };
  };

  render() {
    const { details, history } = this.props;

    const {
      notes,
      start_date,
      end_date,
      site,
      author: { name: author },
    } = details;

    const start = this.getValues(start_date, site.timezone);
    const end = this.getValues(end_date, site.timezone);

    return (
      <Card key={history.location.pathname}>
        <Row form>
          <Col>
            <Label>
              <strong>Start Date</strong>
            </Label>
            <p>{start.date && start.date}</p>
          </Col>
          <Col>
            <Label>
              <strong>Start Time</strong>
            </Label>
            <p>{start.time && start.time}</p>
          </Col>
          <Col>
            <Label>
              <strong>End Date</strong>
            </Label>
            {end.date ? <p>{end.date}</p> : <EmergencyStatus handleEndEmergency={(e) => this.handleEndEmergency(e)} />}
          </Col>
          <Col>
            <Label>
              <strong>End Time</strong>
            </Label>
            <p>{end.time && end.time}</p>
          </Col>
        </Row>
        <Row form>
          <Col>
            <Label>
              <strong>Emergency Author</strong>
            </Label>
            <p>{author}</p>
          </Col>
        </Row>
        <Row form>
          <Col>
            <Label>
              <strong>Notes</strong>
            </Label>
            <p>{notes}</p>
          </Col>
        </Row>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    details: state.siteService.emergency,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    HandleStatusRequest: (modalConfig) => dispatch(handleStatusRequest(modalConfig)),
    CancelStatusRequest: () => dispatch(cancelStatusRequest()),
    EndEmergency: (entity, status, id) => dispatch(endEmergency(entity, status, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
