import PageTitle from '@alliancesafetycouncil/asc-page-title';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getCompanies, getCompany } from '../../../actions/Companies/Companies';
import { clearCurriculums, getCurriculums } from '../../../actions/Networks/Networks';
import { clearSites, getSitesByNetwork } from '../../../actions/Sites/Sites';
import FormContainer from '../../../components/FormContainer/FormContainer';
import { TitleBumper } from '../../../components/common';
import AddGateForm from './AddGateForm';

class AddGates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      networkId: this.props.match.params.networkId,
      id: this.props.match.params.id,
    };
  }

  componentDidMount() {
    const { networkId } = this.state;
    this.props.ClearCurriculums();
    this.props.ClearSites();

    if (networkId) {
      this.props.GetSitesByNetwork(networkId);
      this.props.GetCurriculums(networkId);
    }

    this.props.GetCompanies('asc', 100, 0, 'id');
  }

  render() {
    const { curriculums, networkBusy, companies, dataSite: sites, profile, siteBusy, company } = this.props;
    const { networkId, id } = this.state;

    let data = networkId ? { company: networkId, site: { id: id } } : {};

    return (
      <div className="flex flex-1 flex-col">
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem tag="a" href="#/gates" className="bread-crumb-root">
            Gates
          </BreadcrumbItem>
          <BreadcrumbItem>Add Gate</BreadcrumbItem>
        </Breadcrumb>
        <TitleBumper>
          <PageTitle icon="gate">Add Gate</PageTitle>
        </TitleBumper>
        <FormContainer
          {...this.props}
          entity="gates"
          action="add"
          redirect={'/gates'}
          Form={AddGateForm}
          handleValues={(values) => {
            const curriculum = values?.config?.entry?.iLevel?.curriculum;
            const enabled = values?.entry?.cq?.enabled ?? false;
            return {
              site: values.site,
              notes: values.notes,
              contact: values.contact,
              name: values.name,
              config: {
                entry: {
                  iLevel: {
                    enabled,
                    curriculum: curriculum || '0',
                  },
                },
              },
              status: 'active',
            };
          }}
          getCompany={this.props.GetCompany}
          company={company}
          companies={companies}
          sites={sites}
          getSites={this.props.GetSitesByNetwork}
          siteBusy={siteBusy}
          getCurriculums={this.props.GetCurriculums}
          networkBusy={networkBusy}
          curriculums={curriculums}
          initialValues={data}
          role={profile.role}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    siteBusy: state.siteService.busy,
    company: state.companyService.details,
    companies: state.companyService.data,
    dataSite: state.siteService.data,
    profile: state.authService,
    curriculums: state.networkService.curriculums,
    networkBusy: state.networkService.busy,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetCompany: (id) => dispatch(getCompany(id)),
    GetCompanies: (direction, limit, offset, sort) => dispatch(getCompanies(direction, limit, offset, sort)),
    ClearSites: () => dispatch(clearSites()),
    GetSitesByNetwork: (networkId) => dispatch(getSitesByNetwork(networkId)),
    GetCurriculums: (networkId) => dispatch(getCurriculums(networkId)),
    ClearCurriculums: () => dispatch(clearCurriculums()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddGates);
