import { useEffect, useState } from 'react';

const useThemeConfig = () => {
  const [{ config, error }, setThemeConfig] = useState({ config: null, error: null });

  useEffect(() => {
    const loadThemeConfig = async () => {
      const hostname = window.location.hostname;
      const themeConfigFile = hostname.includes('access.pyvot.com') ? 'pyvot-access.json' : 'default.json';

      try {
        await import(`../../config/theme/${themeConfigFile}`).then((module) => {
          setThemeConfig((state) => ({ ...state, config: module?.default }));
        });
      } catch (error) {
        console.error('Error loading config file:', error);
        setThemeConfig((state) => ({ ...state, error }));
      }
    };

    loadThemeConfig();
  }, []);

  return { config, error };
};

export default useThemeConfig;
