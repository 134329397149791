import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, FormGroup, Row } from 'reactstrap';
import { Field } from 'redux-form';
import styled from 'styled-components';

const CheckBoxWrapper = styled.div`
  position: relative;
`;

const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: #85ba30;
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;
const ToggleSwitchGate = ({ initialValues }) => {
  const [enabled, setEnabled] = useState(false);
  const checked = initialValues?.config?.entry?.cq?.enabled;
  console.log('ToggleInitivalVAl:', initialValues);
  useEffect(() => {
    setEnabled(checked);
  }, [checked]);

  console.log('enabled:', enabled);

  const handleCheckboxChange = () => {
    setEnabled(!enabled);
    console.log('enabled:', !enabled);
  };
  return (
    <FormGroup>
      <Col lg={6}>
        <Row form>
          <Col lg={1}>
            <Field
              id="config.entry.cq.enabled"
              name="config.entry.cq.enabled"
              className="form-control-input"
              label="Enable/Disable CQ"
              component="input"
              type="checkbox"
              onChange={handleCheckboxChange}
              checked={enabled}
            />
          </Col>
        </Row>
      </Col>
    </FormGroup>
  );
};

const mapStateToProps = (state) => {
  return {
    busy: state.gateService.busy,
  };
};

export default connect(mapStateToProps)(ToggleSwitchGate);
