import {
  CONFIGURE_PROFILE,
  SIGNIN_FAILURE,
  SIGNIN_REQUEST,
  SIGNIN_SUCCESS,
  SIGNOUT,
  VERIFY_FAILURE,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
} from "../../actions/Auth/Auth";

const InitialState = {
  busy: false,
  auth: null,
  error: false,
  role: null,
  access_token: null,
};

export default function authService(state = InitialState, action) {
  switch (action.type) {
    case SIGNIN_REQUEST:
      return {
        busy: true,
        auth: false,
      };
    case SIGNIN_SUCCESS:
      return {
        busy: false,
        auth: true,
        role: action.data.role,
        access_token: action.data.access_token,
      };
    case SIGNIN_FAILURE:
      return {
        busy: false,
        auth: false,
        role: null,
        error: action.error,
      };
    case VERIFY_REQUEST:
      return {
        busy: true,
      };
    case VERIFY_SUCCESS:
      return {
        busy: false,
        auth: true,
        role: action.data.roles,
        access_token: action.data.access_token,
      };
    case VERIFY_FAILURE:
      return {
        busy: false,
        auth: false,
        role: null,
      };
    case CONFIGURE_PROFILE:
      return {
        ...state,
        busy: false,
        role: action.data.role,
      };
    case SIGNOUT:
      return {
        auth: false,
        role: null,
        access_token: null,
      };
    default:
      return state;
  }
}
