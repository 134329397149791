import { PROFILE_FAILURE, PROFILE_REQUEST, PROFILE_SUCCESS } from "../../actions/Profile/Profile";

const InitialState = {
  profile: {
    name: "",
    role: "",
    mobile: "",
    email: "",
    company: "",
  },
  busy: null,
  res: null,
  err: null,
};

export default function profileService(state = InitialState, action) {
  switch (action.type) {
    case PROFILE_REQUEST:
      return {
        ...state,
        busy: true,
      };
    case PROFILE_SUCCESS:
      return {
        ...state,
        busy: false,
        res: true,
        profile: action.data.data.data,
      };
    case PROFILE_FAILURE:
      return {
        ...state,
        busy: false,
        err: true,
      };
    default:
      return state;
  }
}
