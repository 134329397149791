import Table from '@alliancesafetycouncil/asc-table';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getSitesByNetwork } from '../../../actions/Sites/Sites';

class SitesTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        { headerName: 'Site', field: 'name', cellRenderer: 'renderName' },
        { headerName: 'Contact', field: 'contact' },
        { headerName: 'Email', field: 'email' },
        { headerName: 'Phone', field: 'phone' },
        {
          headerName: 'Status',
          field: 'status',
          filter: 'agSetColumnFilter',
          valueFormatter: (params) =>
            params.value && params.value.charAt(0).toUpperCase() + params.value.slice(1, params.value.length),
        },
      ],
      customCells: {
        renderName: (props) => {
          return (
            props.data && (
              <Link to={`/sites/site-details/${props.data.id}/${props.data.network.id}/activity-report`}>
                {props.data.name}
              </Link>
            )
          );
        },
      },
      config: [
        {
          type: 'primary',
          size: 'md',
          label: 'Add Site',
          href: `/#/sites/add-site/${this.props.match.params.networkId}`,
        },
      ],
    };
  }

  componentWillMount() {
    this.props.GetSitesByNetwork(this.props.match.params.networkId);
  }

  render() {
    const { data } = this.props;
    const networkId = this.props.match.params.networkId;

    return (
      <Table
        {...this.props}
        className="ag-theme-balham flex flex-1 flex-col relative text-sm"
        columnDefs={this.state.columnDefs}
        getData={() => this.props.GetSitesByNetwork(networkId)}
        customCells={this.state.customCells}
        data={data}
        headerConfig={this.state.config}
        customCells={this.state.customCells}
        defaultColDef={this.state.defaultColDef}
        filter_type="any"
        getGridApi={this.getGridApi}
        enablePagination={false}
        defaultFilter={{ column: 'status', value: 'active' }}
        rowHeight={50}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.siteService.busy,
    res: state.siteService.res,
    err: state.siteService.err,
    data: state.siteService.data,
    details: state.companyService.details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetSitesByNetwork: (networkId) => dispatch(getSitesByNetwork(networkId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SitesTab);
