import React from 'react';

export default function LogoLightDefault() {
  return (
    <div className="py-2 text-3xl text-white">
      <span className="font-bold">Gate</span>
      <span className="font-thin">Check</span>
    </div>
  );
}
