import MainCard from "@alliancesafetycouncil/asc-card";
import Moment from "moment-timezone";
import React from "react";
import { Col, Form, FormGroup, Label, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import HiddenInput from "../../../components/FormContainer/HiddenInput";
import TextInput from "../../../components/FormContainer/TextInput";
import { relativeFormats } from "../../../library/Utilities";
import { required } from "../../../library/Validation";
import ASCButton from "@alliancesafetycouncil/asc-button";

const formatDate = (value, timezone) => {
  return value && timezone ? Moment(value).tz(timezone).calendar(null, relativeFormats()) + "," : "";
};

const formatTime = (value, timezone) => {
  return value && timezone ? Moment(value).tz(timezone).format("h:mm A zz") : "";
};

let AddEmergencyForm = ({ handleSubmit, pristine, busy, start_date, timezone, history }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <MainCard type="main">
        <Row form>
          <Col lg={6}>
            <FormGroup>
              <Field
                type="text"
                name="name"
                id="name"
                placeholder=""
                label="Emergency Name"
                component={TextInput}
                className="form-control"
                validate={[required]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={12}>
            <FormGroup>
              <Label for="exampleText" className="primary-heading">
                Start Date / Time
              </Label>
              <p>
                {formatDate(start_date, timezone)} {formatTime(start_date, timezone)}
              </p>
            </FormGroup>
          </Col>
        </Row>
        <Field type="number" name="site.id" id="site.id" component={HiddenInput} />
        <Row form>
          <Col lg={12}>
            <FormGroup>
              <Label for="notes" className="primary-heading">
                Notes
              </Label>
              <Field id="notes" name="notes" component="textarea" className="form-control" />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col lg={12}>
            <FormGroup>
              <div className="flex flex-row">
                <div className="mr-2">
                  <ASCButton type="save" size="md" onClick={handleSubmit} disabled={pristine || busy}>
                    Save
                  </ASCButton>
                </div>
                <div>
                  <ASCButton type="tertiary" size="md" onClick={() => history.goBack()}>
                    Cancel
                  </ASCButton>
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </MainCard>
    </Form>
  );
};

AddEmergencyForm = reduxForm({
  form: "addEmergency",
})(AddEmergencyForm);

export default AddEmergencyForm;
