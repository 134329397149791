import axios from 'axios';

import Config from '../../config/env/config.json';

export function getGates(direction, limit, offset, sort) {
  return async (dispatch) => {
    dispatch(getGatesRequest());
    return axios
      .get(`${Config.api.endpoint}/gates?sortDirection=${direction}&limit=${limit}&offset=${offset}&sortColumn=${sort}`)
      .then((res) => {
        dispatch(getGatesSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getGatesFailure('Could not fetch.'));
      });
  };
}

export function getGate(gateId, networkId) {
  return async (dispatch) => {
    dispatch(getGateRequest());
    return axios
      .get(`${Config.api.endpoint}/gates/${gateId}?networkId=${networkId}`)
      .then((res) => {
        dispatch(getGateSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getGateFailure(err));
      });
  };
}

export function getGatesByNetwork(networkId) {
  return async (dispatch) => {
    dispatch(getGatesRequest());
    return axios
      .get(`${Config.api.endpoint}/gates?networkId=${networkId}`)
      .then((res) => {
        dispatch(getGatesSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getGatesFailure('Could not fetch.'));
      });
  };
}

export function getGatesByNetworkAndSite(networkId, siteId) {
  return async (dispatch) => {
    dispatch(getGatesRequest());
    return axios
      .get(`${Config.api.endpoint}/gates?networkId=${networkId}&siteId=${siteId}`)
      .then((res) => {
        dispatch(getGatesSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getGatesFailure('Could not fetch.'));
      });
  };
}

export const SET_GATES_TOTAL = 'SET_GATES_TOTAL';
export function setGatesTotal(data) {
  return {
    type: SET_GATES_TOTAL,
    data,
  };
}

export const GET_GATE_REQUEST = 'GET_GATE_REQUEST';
function getGateRequest() {
  return {
    type: GET_GATE_REQUEST,
  };
}

export const GET_GATE_SUCCESS = 'GET_GATE_SUCCESS';
function getGateSuccess(data) {
  return {
    type: GET_GATE_SUCCESS,
    data,
  };
}

export const GET_GATE_FAILURE = 'GET_GATE_FAILURE';
function getGateFailure(err) {
  return {
    type: GET_GATE_FAILURE,
    err,
  };
}

export const GET_GATES_REQUEST = 'GET_GATES_REQUEST';
function getGatesRequest() {
  return {
    type: GET_GATES_REQUEST,
  };
}

export const GET_GATES_SUCCESS = 'GET_GATES_SUCCESS';
function getGatesSuccess(data) {
  return {
    type: GET_GATES_SUCCESS,
    data,
  };
}

export const GET_GATES_FAILURE = 'GET_GATES_FAILURE';
function getGatesFailure(error) {
  return {
    type: GET_GATES_FAILURE,
    error,
  };
}

export const UPDATE_GATE = 'UPDATE_GATE';
export function updateGate(data) {
  return {
    type: UPDATE_GATE,
    data,
  };
}
