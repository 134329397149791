import React, { Component } from "react";
import { connect } from "react-redux";
import { Toast, ToastBody } from "reactstrap";
import { ackToast, addEntity, editEntity } from "../../actions/Form/Form";

let back = false;
class FormContainer extends Component {
  constructor(props) {
    super(props);
    back = false;

    this.state = {
      networkId: this.props.match.params.networkId,
    };
  }

  handleAdd = (values) => {
    const { handleValues = (values) => values, AddEntity, entity } = this.props;

    AddEntity(entity, handleValues(values), this.state.networkId);
  };

  handleEdit = (values) => {
    const { handleValues = (values) => values, EditEntity, entity } = this.props;

    EditEntity(entity, handleValues(values), this.state.networkId, values.id);
  };

  handleNetworkId = (networkId) => {
    this.setState({
      networkId: networkId,
    });
  };

  render() {
    const { AckToast, Form, action, busy, res, err, redirect } = this.props;

    const onSubmit = action === "edit" ? this.handleEdit : this.handleAdd;

    const isSuccess = !busy && res;
    const isFailure = !busy && err;

    if (isSuccess || isFailure) {
      const timer = setTimeout(() => {
        AckToast();
        clearTimeout(timer);
      }, 5000);
    }

    if (isSuccess) {
      if (redirect) {
        this.props.history.push(redirect);
      } else {
        if (!back) {
          back = true;
          this.props.history.goBack();
        }
      }
    }

    return (
      <div>
        <Form
          {...this.props}
          onSubmit={!busy && onSubmit}
          busy={busy}
          handleNetworkId={this.handleNetworkId}
          networkId={this.state.networkId}
        />
        <Toast onClick={AckToast} isOpen={isSuccess} className="toast success">
          <ToastBody>Success!</ToastBody>
        </Toast>
        <Toast onClick={AckToast} isOpen={isFailure} className="toast error">
          <ToastBody>
            <p>{err}</p>
          </ToastBody>
        </Toast>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.formService.busy,
    res: state.formService.res,
    err: state.formService.err,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    AddEntity: (values, entity, networkId) => dispatch(addEntity(values, entity, networkId)),
    EditEntity: (values, entity, networkId, id) => dispatch(editEntity(values, entity, networkId, id)),
    AckToast: () => dispatch(ackToast()),
  };
};

FormContainer = connect(mapStateToProps, mapDispatchToProps)(FormContainer);

export default FormContainer;
