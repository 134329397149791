import axios from 'axios';

export function setToken(access_token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
  localStorage.setItem('access_token', access_token);
}

export function clearToken() {
  axios.defaults.headers.common['Authorization'] = '';
  localStorage.removeItem('access_token');
}

export function getRoles(role) {
  const roles = [
    {
      value: 'gateguard',
      label: 'Gate Guard',
    },
    {
      value: 'siteadmin',
      label: 'Site Admin',
    },
  ];

  if (role === 'owneradmin' || role === 'superadmin') {
    roles.push({
      value: 'owneradmin',
      label: 'Owner Admin',
    });
  }

  if (role === 'superadmin') {
    roles.push({
      value: 'superadmin',
      label: 'Super Admin',
    });
  }

  return roles;
}

export function getTimezonesNames() {
  return {
    CST: 'Central Standard Time',
    CDT: 'Central Daylight Time',
    EST: 'Eastern Standard Time',
    EDT: 'Eastern Daylight Time',
    MST: 'Mountain Standard Time',
    MDT: 'Mountain Daylight Time',
    PST: 'Pacific Standard Time',
    PDT: 'Pacific Daylight Time',
  };
}

export function getTimezones() {
  return [
    {
      label: 'Central Standard Time (CST)',
      timezone: 'America/Chicago',
    },
    {
      label: 'Eastern Standard Time (EST)',
      timezone: 'America/New_York',
    },
    {
      label: 'Mountain Standard Time (MST)',
      timezone: 'America/Denver',
    },
    {
      label: 'Pacific Standard Time (PST)',
      timezone: 'America/Los_Angeles',
    },
  ];
}

export function relativeFormats() {
  return {
    sameDay: '[Today]',
    nextDay: 'M/D/YYYY',
    nextWeek: 'M/D/YYYY',
    lastDay: 'M/D/YYYY',
    lastWeek: 'M/D/YYYY',
    sameElse: 'M/D/YYYY',
  };
}

export function debounce(func, wait) {
  let timeout;

  return function () {
    const action = () => {
      clearTimeout(timeout);
      func();
    };

    clearTimeout(timeout);
    timeout = setTimeout(action, wait);
  };
}

export function setUser(data) {
  window.user = {
    role: data.role,
    permissions: data.permissions || [],
  };

  if (data.network) {
    const network = data.network[0];
    const company = network.company[0];

    window.user['network_id'] = network.id;
    window.user['company_id'] = company.id;

    if (company.site) {
      window.user['site_id'] = company.site[0].id;
    }
  }

  Object.freeze(window.user);
}
