import PageTitle from '@alliancesafetycouncil/asc-page-title';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem, Toast, ToastBody } from 'reactstrap';
import { getEmergency } from '../../../actions/Sites/Sites';
import { ackToast } from '../../../actions/Status/Status';
import TabDisplay from '../../../components/TabDisplay/TabDisplay';
import Details from './Details';
import MusterReport from './MusterReport';

class EmergencyDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      config: [
        {
          name: 'Muster Report',
          path: '/muster-report',
          component: MusterReport,
          default: true,
          exact: true,
        },
        {
          name: 'Details',
          path: '/details',
          component: Details,
          exact: true,
        },
      ],
    };
  }

  componentWillMount() {
    const { id, networkId } = this.props.match.params;

    this.props.GetEmergency(id, networkId);
  }

  componentDidUpdate(prevProps, prevState) {
    const { id, networkId } = this.props.match.params;

    if (prevProps.match.params.id !== id) {
      this.props.GetEmergency(id, networkId);
    }
  }

  render() {
    const {
      AckToast,
      busy,
      res,
      err,
      details,
      match: {
        params: { id, networkId },
      },
    } = this.props;
    const { company, site, name } = details;

    const isSuccess = !busy && res;
    const isFailure = !busy && err;

    if (isSuccess || isFailure) {
      const timer = setTimeout(() => {
        AckToast();
        clearTimeout(timer);
      }, 5000);
    }

    return (
      <div className="flex flex-1 flex-col">
        <div className="container-heading">
          <Breadcrumb tag="nav" listTag="div">
            <BreadcrumbItem tag="a" href={`/#/companies/company-details/${networkId}/${company.id}/activity-report`}>
              {company.name}
            </BreadcrumbItem>
            <BreadcrumbItem tag="a" href={`/#/sites/site-details/${site.id}/${networkId}/gates`}>
              {site.name}
            </BreadcrumbItem>
            <BreadcrumbItem active>{name}</BreadcrumbItem>
          </Breadcrumb>
          <PageTitle icon="gate" editLink={`#/emergency/edit-emergency/${id}/${networkId}`}>
            {name}
          </PageTitle>
        </div>
        <TabDisplay
          {...this.props}
          tabs={this.state.config}
          edit={{
            path: `#/emergency/edit-emergency/${id}/${networkId}`,
            label: 'Edit Emergency',
          }}
        />
        <Toast onClick={AckToast} isOpen={isSuccess} className="toast success">
          <ToastBody>Success!</ToastBody>
        </Toast>
        <Toast onClick={AckToast} isOpen={isFailure} className="toast error">
          <ToastBody>
            <p>{err && err.message}</p>
          </ToastBody>
        </Toast>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.statusService.busy,
    res: state.statusService.res,
    err: state.statusService.err,
    details: state.siteService.emergency,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetEmergency: (emergencyId, networkId) => dispatch(getEmergency(emergencyId, networkId)),
    AckToast: () => dispatch(ackToast()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmergencyDetails);
