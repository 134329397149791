import PageTitle from '@alliancesafetycouncil/asc-page-title';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getCompany } from '../../../actions/Companies/Companies';
import FormContainer from '../../../components/FormContainer/FormContainer';
import { TitleBumper } from '../../../components/common';
import EditCompanyForm from './EditCompanyForm';

class EditCompany extends Component {
  componentWillMount() {
    const { id } = this.props.match.params;
    this.props.GetCompany(id);
  }

  render() {
    const { data, profile, match } = this.props;
    const { id, networkId } = match.params;

    return (
      <div className="flex flex-1 flex-col">
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem tag="a" href="#/companies" className="bread-crumb-root">
            Companies
          </BreadcrumbItem>
          <BreadcrumbItem tag="a">Edit Company</BreadcrumbItem>
        </Breadcrumb>
        <TitleBumper>
          <PageTitle icon="company">Edit Company</PageTitle>
        </TitleBumper>
        <FormContainer
          {...this.props}
          entity="companies"
          action="edit"
          redirect={`/companies/company-details/${networkId}/${id}/details`}
          Form={EditCompanyForm}
          handleValues={(values) => {
            let features = [];
            if (values.hasMusterFeature) {
              features.push('muster');
            }
            if (values.hasBlockFeature) {
              features.push('block');
            }
            if (values.hasGuardOverride) {
              features.push('guardOverride');
            }
            const entry = {
              iLevel: values.entry.iLevel,
              cq: {
                enabled: false,
              },
            };
            if (values.entry.cq && values.entry.cq.enabled) {
              entry.cq = values.entry.cq;
              entry.cq.client_id = entry.cq.client_id || '';
            }
            return {
              name: values.name,
              contact: values.contact,
              status: values.status,
              notes: values.notes,
              entry,
              config: {
                features,
              },
            };
          }}
          initialValues={data}
          role={profile.role}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.companyService.details,
    profile: state.authService,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetCompany: (id) => dispatch(getCompany(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCompany);
