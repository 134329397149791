import PageTitle from '@alliancesafetycouncil/asc-page-title';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getGate } from '../../../actions/Gates/Gates';
import { clearCurriculums, getCurriculums } from '../../../actions/Networks/Networks';
import FormContainer from '../../../components/FormContainer/FormContainer';
import { TitleBumper } from '../../../components/common';
import EditGateForm from './EditGateForm';
class EditGate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gateId: this.props.match.params.id,
      networkId: this.props.match.params.networkId,
      error: false,
      success: false,
      message: null,
    };

    this.entry = props.details.config?.entry?.cq || {};
  }

  componentDidMount() {
    const { networkId } = this.state;
    this.props.ClearCurriculums();

    if (networkId) {
      this.props.GetCurriculums(networkId);
      this.props.GetGate(this.state.gateId, this.state.networkId);
    }
  }

  render() {
    const { curriculums, networkBusy } = this.props;
    const { gateId, networkId } = this.state;
    const { details, dataSite, data } = this.props;
    // const data = data.details;
    console.log('data details:', data);
    // console.log('cq:', cq)
    console.log('details:', details);

    return (
      <div className="flex flex-1 flex-col">
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem tag="a" href="#/gates" className="bread-crumb-root">
            Gates
          </BreadcrumbItem>
          <BreadcrumbItem>Add Gate</BreadcrumbItem>
        </Breadcrumb>
        <TitleBumper>
          <PageTitle icon="gate">Edit Gate</PageTitle>
        </TitleBumper>
        <FormContainer
          {...this.props}
          entity="gates"
          action="edit"
          redirect={`/gates/gate-details/${gateId}/${networkId}/details`}
          Form={EditGateForm}
          entry={this.entry}
          handleValues={(values) => {
            console.log('handleValues:', values);

            const config = values.config || {};
            const entry = config.entry || {};

            console.log('config:', config);
            console.log('entry:', entry);

            const curriculum = entry.iLevel?.curriculum;
            const enabled = !(parseInt(curriculum) === -1);

            console.log('curriculum:', curriculum);
            console.log('enabled:', enabled);

            entry.iLevel = {
              ...entry.iLevel,
              enabled,
            };

            const cq = typeof entry.cq === 'object' ? entry.cq : {};
            cq.enabled = values.config.entry.cq.enabled;

            return {
              site: {
                id: values.site.id,
              },
              config: {
                ...config,
                entry: {
                  ...entry,
                  cq,
                },
              },
              notes: values.notes,
              contact: values.contact,
              name: values.name,
              status: 'active',
            };
          }}
          initialValues={{
            ...details,
            config: {
              ...details.config,
              entry: {
                ...(details.config?.entry || {}),
                cq: {
                  ...(data.details.config?.entry?.cq || {}),
                  enabled: data.details.config?.entry?.cq?.enabled || false,
                },
              },
            },
          }}
          companies={[details.company]}
          sites={dataSite}
          networkBusy={networkBusy}
          curriculums={curriculums}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const cq = state.gateService.details.config.entry?.cq || {};

  return {
    details: state.gateService.details,
    data: state.gateService,
    companies: state.companyService.data,
    sites: state.siteService.data,
    curriculums: state.networkService.curriculums,
    networkBusy: state.networkService.busy,
    dataSite: state.siteService.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetGate: (gateId, networkId) => dispatch(getGate(gateId, networkId)),
    GetCurriculums: (networkId) => dispatch(getCurriculums(networkId)),
    ClearCurriculums: () => dispatch(clearCurriculums()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGate);
