import PageTitle from '@alliancesafetycouncil/asc-page-title';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getCompanies } from '../../../actions/Companies/Companies';
import { clearCurriculums, getCurriculums } from '../../../actions/Networks/Networks';
import { TitleBumper } from '../../../components/common';
import FormContainer from '../../../components/FormContainer/FormContainer';
import { getTimezones } from '../../../library/Utilities';
import AddSitesForm from './AddSiteForm';

class AddSites extends Component {
  constructor(props) {
    super(props);

    this.state = {
      networkId: props.match.params.networkId,
      error: false,
      success: false,
      message: null,
    };
  }

  componentDidMount() {
    const { networkId } = this.state;
    this.props.ClearCurriculums();
    if (networkId) {
      this.props.GetCurriculums(networkId);
    }
    this.props.GetCompanies('asc', 100, 0, 'id');
  }

  render() {
    const { companies, curriculums, company, networkBusy } = this.props;
    const { networkId } = this.state;

    if (!companies) {
      return <div />;
    }

    let data = networkId ? { company: networkId } : {};

    return (
      <div className="flex flex-1 flex-col">
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem tag="a" href="#/sites" className="bread-crumb-root">
            Sites
          </BreadcrumbItem>
          <BreadcrumbItem>Add Site</BreadcrumbItem>
        </Breadcrumb>
        <TitleBumper>
          <PageTitle icon="site">Add Site</PageTitle>
        </TitleBumper>
        <FormContainer
          {...this.props}
          entity="sites"
          action="add"
          redirect={'/sites'}
          Form={AddSitesForm}
          handleValues={(values) => {
            const curriculum = values?.entry?.iLevel?.curriculum;
            const enabled = !(parseInt(curriculum) === -1);
            values.entry.iLevel.enabled = enabled;

            let features = [];
            if (values.hasMusterFeature) {
              features.push('muster');
            }
            if (values.hasBlockFeature) {
              features.push('block');
            }

            return {
              entry: values.entry,
              notes: values.notes,
              contact: values.contact,
              config: {
                ...values.config,
                features,
              },
              name: values.name,
              status: values.status,
            };
          }}
          company={company}
          companies={companies}
          getCurriculums={this.props.GetCurriculums}
          networkBusy={networkBusy}
          curriculums={curriculums}
          initialValues={data}
          timezones={getTimezones()}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    networkBusy: state.networkService.busy,
    company: state.companyService.details,
    companies: state.companyService.data,
    details: state.companyService.details,
    curriculums: state.networkService.curriculums,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetCompanies: (direction, limit, offset, sort) => dispatch(getCompanies(direction, limit, offset, sort)),
    GetCurriculums: (networkId) => dispatch(getCurriculums(networkId)),
    ClearCurriculums: () => dispatch(clearCurriculums()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSites);
