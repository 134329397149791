import Card from '@alliancesafetycouncil/asc-card';
import PageTitle from '@alliancesafetycouncil/asc-page-title';
import Table from '@alliancesafetycouncil/asc-table';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { getSites } from '../../../actions/Sites/Sites';
import { TitleBumper } from '../../../components/common';

class Sites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        direction: 'asc',
        limit: 100,
        offset: 0,
        sort: 'id',
      },
      columnDefs: [
        { headerName: 'Site', field: 'name', cellRenderer: 'renderName' },
        { headerName: 'Company', field: 'company' },
        { headerName: 'Contact', field: 'contact' },
        { headerName: 'Phone', field: 'phone' },
        { headerName: 'Notes', field: 'notes' },

        {
          headerName: 'Status',
          field: 'status',
          filter: 'agSetColumnFilter',
          valueFormatter: (params) =>
            params.value && params.value.charAt(0).toUpperCase() + params.value.slice(1, params.value.length),
          filterParams: {
            newRowsAction: 'keep',
          },
        },
      ],
      customCells: {
        renderName: (row) => {
          const networkId = row.data.network ? `/${row.data.network.id}` : '';
          return <Link to={`/sites/site-details/${row.data.id}${networkId}/activity-report`}>{row.data.name}</Link>;
        },
      },
      config: [
        {
          type: 'primary',
          size: 'md',
          label: 'Add Site',
          href: '/#/sites/add-site',
        },
      ],
    };
  }

  componentWillMount() {
    const { direction, limit, offset, sort } = this.state.params;

    this.props.GetSites(direction, limit, offset, sort);
  }

  render() {
    return (
      <Fragment>
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem active>All Sites</BreadcrumbItem>
        </Breadcrumb>
        <TitleBumper>
          <PageTitle icon="site">All Sites</PageTitle>
        </TitleBumper>
        <Card type="main">
          <Table
            {...this.props}
            className="ag-theme-balham flex flex-1 flex-col relative text-sm"
            columnDefs={this.state.columnDefs}
            getData={this.props.GetSites}
            data={this.props.data}
            headerConfig={this.state.config}
            customCells={this.state.customCells}
            defaultColDef={this.state.defaultColDef}
            filter_type="any"
            getGridApi={this.getGridApi}
            enablePagination={false}
            defaultFilter={{ column: 'status', value: 'active' }}
            rowHeight={50}
          />
        </Card>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.siteService.busy,
    res: state.siteService.res,
    err: state.siteService.err,
    data: state.siteService.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetSites: (direction, limit, offset, sort) => dispatch(getSites(direction, limit, offset, sort)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sites);
