import PageTitle from "@alliancesafetycouncil/asc-page-title";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import FormContainer from "../../../components/FormContainer/FormContainer";
import AddEmergencyForm from "./AddEmergencyForm";

class AddEmergency extends Component {
  render() {
    const { id, networkId } = this.props.match.params;
    const { details } = this.props;

    const data = {
      site: {
        id: Number(id),
      },
    };

    return (
      <div className="flex flex-1 flex-col">
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem tag="a" href="#/emergency" className="bread-crumb-root">
            Emergency
          </BreadcrumbItem>
          <BreadcrumbItem>Add Emergency</BreadcrumbItem>
        </Breadcrumb>
        <PageTitle icon="gate">Add Emergency</PageTitle>
        <FormContainer
          {...this.props}
          entity="emergencies"
          action="add"
          redirect={`/sites/site-details/${id}/${networkId}/emergencies`}
          requiresNetworkId={true}
          initialValues={data}
          start_date={new Date()}
          timezone={details.config.timezone}
          Form={AddEmergencyForm}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    details: state.siteService.details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEmergency);
