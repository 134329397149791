import PageTitle from '@alliancesafetycouncil/asc-page-title';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem, Button, Modal, ModalBody, ModalFooter, Toast, ToastBody } from 'reactstrap';
import { getCompany } from '../../../actions/Companies/Companies';
import { getGatesByNetworkAndSite } from '../../../actions/Gates/Gates';
import { getActiveEmergencies, getSite } from '../../../actions/Sites/Sites';
import { ackToast } from '../../../actions/Status/Status';
import { TitleBumper } from '../../../components/common';
import TabDisplay from '../../../components/TabDisplay/TabDisplay';
import ActivityTab from './ActivityTab';
import DetailsTab from './DetailsTab';
import Emergencies from './Emergencies';
import GatesTab from './GatesTab';
import MusterLocations from './MusterLocations';

class SiteDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emergencyModal: false,
      config: [
        {
          name: 'Activity Report',
          path: '/activity-report',
          component: ActivityTab,
          default: true,
          exact: true,
        },
        {
          name: 'Gates',
          path: '/gates',
          component: GatesTab,
          exact: true,
          add: {
            path: '#/gates/add-gate',
            label: 'Add Gate',
          },
        },
        {
          name: 'Muster Locations',
          path: '/muster-locations',
          component: MusterLocations,
          exact: true,
          add: {
            path: '#/muster-location/add-location',
            label: 'Add Muster Location',
          },
          disabled: true,
        },
        {
          name: 'Emergencies',
          path: '/emergencies',
          component: Emergencies,
          exact: true,
          condition: {
            eval: (values, site) => values.find((item) => item.site === site),
            // the key to pull from props for eval
            input: 'activeEmergencies',
          },
          busy: 'activeEmergencyBusy',
          alt: () => this.handleEmergencyModal(),
          add: {
            path: '#/emergency/add-emergency',
            label: 'Add Emergency',
          },
          disabled: true,
        },
        {
          name: 'Details',
          path: '/details',
          component: DetailsTab,
          exact: true,
        },
      ],
    };
  }

  handleEmergencyModal = () => {
    this.setState({
      emergencyModal: !this.state.emergencyModal,
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.siteDetails.company.id && this.props.siteDetails.company.id !== prevProps.siteDetails.company.id) {
      this.props.GetCompany(this.props.siteDetails.company.id);
    }
  }

  componentWillMount() {
    const { id, networkId } = this.props.match.params;

    this.props.GetSite(networkId, id);
    this.props.GetActiveEmergencies();
  }

  render() {
    const {
      AckToast,
      busy,
      res,
      err,
      details,
      match: {
        params: { id, networkId },
      },
    } = this.props;

    let { config } = this.state;
    const { emergencyModal } = this.state;

    const {
      company,
      name,
      config: { features = [] },
    } = details;

    const isSuccess = !busy && res;
    const isFailure = !busy && err;

    if (isSuccess || isFailure) {
      const timer = setTimeout(() => {
        AckToast();
        clearTimeout(timer);
      }, 5000);
    }

    details.entity = {
      id: id,
    };

    if (features.includes('muster')) {
      // Update Muster Location Tab
      config[2] = {
        ...config[2],
        disabled: false,
      };

      // Update Emergency Tab
      config[3] = {
        ...config[3],
        disabled: false,
      };
    } else {
      // Update Muster Location Tab
      config[2] = {
        ...config[2],
        disabled: true,
      };

      // Update Emergency Tab
      config[3] = {
        ...config[3],
        disabled: true,
      };
    }

    return (
      <div className="flex flex-1 flex-col">
        <Breadcrumb tag="nav" listTag="div">
          <BreadcrumbItem tag="a" href={`/#/companies/company-details/${networkId}/${company.id}/activity-report`}>
            {company.name}
          </BreadcrumbItem>
          <BreadcrumbItem active>{name}</BreadcrumbItem>
        </Breadcrumb>
        <TitleBumper>
          <PageTitle icon="site" editLink={`#/sites/edit-site/${id}/${networkId}`}>
            {name}
          </PageTitle>
        </TitleBumper>
        <TabDisplay
          {...this.props}
          tabs={this.state.config}
          edit={{
            path: `#/sites/edit-site/${id}/${networkId}`,
            label: 'Edit Site',
          }}
        />
        <Toast onClick={AckToast} isOpen={isSuccess} className="toast success">
          <ToastBody>Success!</ToastBody>
        </Toast>
        <Toast onClick={AckToast} isOpen={isFailure} className="toast error">
          <ToastBody>
            <p>{err && err.message}</p>
          </ToastBody>
        </Toast>
        <Modal isOpen={emergencyModal} toggleModal={this.handleEmergencyModal} className={'className'}>
          <ModalBody>There is already an active emergency in progress.</ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={this.handleEmergencyModal}>
              Close
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.statusService.busy,
    res: state.statusService.res,
    err: state.statusService.err,
    details: state.siteService.details,
    totalGates: state.gateService.total,
    activeEmergencies: state.siteService.activeEmergencies,
    activeEmergencyBusy: state.siteService.activeEmergencyBusy,
    siteDetails: state.siteService.details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetCompany: (id) => dispatch(getCompany(id)),
    GetActiveEmergencies: () => dispatch(getActiveEmergencies()),
    GetSite: (networkId, siteId) => dispatch(getSite(networkId, siteId)),
    GetGatesByNetworkAndSite: (networkId, siteId) => dispatch(getGatesByNetworkAndSite(networkId, siteId)),
    AckToast: () => dispatch(ackToast()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteDetails);
