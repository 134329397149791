import {
  GET_GATES_FAILURE,
  GET_GATES_REQUEST,
  GET_GATES_SUCCESS,
  GET_GATE_FAILURE,
  GET_GATE_REQUEST,
  GET_GATE_SUCCESS,
  SET_GATES_TOTAL,
  UPDATE_GATE,
} from '../../actions/Gates/Gates';

const InitialState = {
  busy: false,
  res: false,
  err: false,
  details: {
    name: '',
    company: {
      id: '',
      name: '',
      network: {
        id: '',
      },
    },
    site: {
      id: '',
      name: '',
    },
    contact: {
      name: '',
      email: '',
      phone: '',
    },
    config: {
      entry: {
        iLevel: {
          curriculum: 0,
        },
        cq: {
          enabled: false,
        },
      },
    },
    notes: '',
    network: {
      id: '',
    },
    status: '',
  },
  data: [],
  total: 0,
};

export default function gateService(state = InitialState, action) {
  switch (action.type) {
    case GET_GATES_REQUEST:
      return {
        ...state,
        busy: true,
        res: false,
        data: [],
      };
    case GET_GATES_SUCCESS:
      return {
        ...state,
        busy: false,
        res: true,
        data: action.data.data.gates,
        total: action.data.data.gates.length,
      };
    case GET_GATES_FAILURE:
      return {
        ...state,
        busy: false,
        res: false,
        err: action.error,
      };
    case GET_GATE_REQUEST:
      return {
        ...state,
        busy: true,
        details: InitialState.details,
      };
    case GET_GATE_SUCCESS:
      const { data } = action.data;
      const { config } = data;
      const cqEnabled = config && config.entry && config.entry.cq && config.entry.cq.enabled;
      const hasCQEnabled = cqEnabled !== false && cqEnabled !== null;
      return {
        ...state,
        busy: false,
        err: false,
        res: true,
        details: {
          ...data,
          hasCQEnabled,
          company: {
            ...data.company,
            network: {
              id: action.data.network.id,
            },
          },
        },
      };
    case GET_GATE_FAILURE:
      return {
        ...state,
        busy: false,
        err: true,
        res: false,
      };
    case UPDATE_GATE:
      return {
        ...state,
        details: {
          ...state.details,
          ...action.data,
        },
      };
    case SET_GATES_TOTAL:
      return {
        ...state,
        total: action.data,
      };
    default:
      return state;
  }
}
