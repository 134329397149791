import Table from '@alliancesafetycouncil/asc-table';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getGatesByNetworkAndSite } from '../../../actions/Gates/Gates';

class GatesTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        { headerName: 'Gate Name', field: 'name', cellRenderer: 'renderName' },
        { headerName: 'Parent Site', field: 'site' },
        { headerName: 'Parent Company', field: 'company' },
        { headerName: 'Contact Name', field: 'contact' },
        { headerName: 'Contact Phone', field: 'phone' },
        { headerName: 'Notes', field: 'notes' },
        { headerName: 'Status', field: 'status' },
      ],
      customCells: {
        renderName: (row) => {
          return (
            row.data && (
              <Link to={`/gates/gate-details/${row.data.id}/${props.match.params.networkId}/details`}>
                {row.data.name}
              </Link>
            )
          );
        },
      },
      config: [
        {
          type: 'primary',
          size: 'md',
          label: 'Add Gate',
          href: `/#/gates/add-gate/${this.props.match.params.id}/${this.props.match.params.networkId}`,
        },
      ],
    };
  }

  componentWillMount() {
    this.props.GetGatesByNetworkAndSite(this.props.match.params.networkId, this.props.match.params.id);
  }

  render() {
    const networkId = this.props.match.params.networkId;
    const siteId = this.props.match.params.id;

    return (
      <Table
        {...this.props}
        className="ag-theme-balham flex flex-1 flex-col relative text-sm"
        columnDefs={this.state.columnDefs}
        getData={() => this.props.GetGatesByNetworkAndSite(networkId, siteId)}
        data={this.props.data}
        headerConfig={this.state.config}
        customCells={this.state.customCells}
        defaultColDef={this.state.defaultColDef}
        filter_type="any"
        getGridApi={this.getGridApi}
        enablePagination={false}
        defaultFilter={{ column: 'status', value: 'active' }}
        rowHeight={50}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.gateService.busy,
    res: state.gateService.res,
    err: state.gateService.err,
    data: state.gateService.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetGatesByNetworkAndSite: (networkId, siteId) => dispatch(getGatesByNetworkAndSite(networkId, siteId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GatesTab);
