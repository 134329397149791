import React, { Component } from "react";

export class RowTools extends Component {
  render() {
    const value = this.props.value;

    const thisBell = (color) => {
      return (
        <div
          style={{
            height: "40px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <i
            className="fas fa-bell"
            onClick={() => {
              return false;
            }}
            style={{
              color: color,
              fontSize: "14px",
            }}
          ></i>
          {/* <p>{color}</p> */}
        </div>
      );
    };
    if (value === "expired" || value === "caution") {
      return thisBell("rgba(27.4%, 30.3%, 83.6%, 0.600)");
    } else {
      return thisBell("#ddd");
    }
  }
}
