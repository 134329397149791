import Table from '@alliancesafetycouncil/asc-table';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUsersByNetwork } from '../../../actions/Users/Users';

class UsersTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        { headerName: 'Name', field: 'name', cellRenderer: 'renderName' },
        { headerName: 'Company', field: 'company' },
        { headerName: 'Roles', field: 'roles' },
        { headerName: 'Login', field: 'login' },
        { headerName: 'Notes', field: 'notes' },
        {
          headerName: 'Status',
          field: 'status',
          filter: 'agSetColumnFilter',
          valueFormatter: (params) => {
            const value = params.value;

            if (typeof value === 'string') {
              return value.charAt(0).toUpperCase() + params.value.slice(1, params.value.length);
            }

            return value;
          },
        },
      ],
      customCells: {
        renderName: (row) => {
          return (
            row.data && (
              <Link to={`/users/user-details/${row.data.id}/${props.details.network.id}/details`}>{row.data.name}</Link>
            )
          );
        },
      },
      config: [
        {
          type: 'primary',
          size: 'md',
          label: 'Add User',
          href: `/#/users/add-user/${this.props.match.params.networkId}`,
        },
      ],
    };
  }

  componentWillMount() {
    this.props.GetUsersByNetwork(this.props.match.params.networkId);
  }

  render() {
    const { data } = this.props;
    const networkId = this.props.match.params.networkId;

    return (
      <Table
        {...this.props}
        className="ag-theme-balham flex flex-1 flex-col relative text-sm"
        columnDefs={this.state.columnDefs}
        getData={() => this.props.GetUsersByNetwork(networkId)}
        data={data}
        headerConfig={this.state.config}
        customCells={this.state.customCells}
        defaultColDef={this.state.defaultColDef}
        filter_type="any"
        getGridApi={this.getGridApi}
        enablePagination={false}
        defaultFilter={{ column: 'status', value: 'active' }}
        rowHeight={50}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.userService.busy,
    res: state.userService.res,
    err: state.userService.err,
    data: state.userService.data,
    details: state.companyService.details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetUsersByNetwork: (networkId) => dispatch(getUsersByNetwork(networkId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersTab);
