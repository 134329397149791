import axios from 'axios';

import Config from '../../config/env/config.json';
import { updateCompany } from '../Companies/Companies';
import { updateGate } from '../Gates/Gates';
import { getActiveEmergencies, updateEmergency, updateMusterLocation, updateSite } from '../Sites/Sites';
import { setToastFailure, setToastSuccess } from '../Toast/Toast';
import { updateUser } from '../Users/Users';
import { updateVisitor } from '../Visitors/Visitors';

export const SET_STATUS = 'SET_STATUS';
export function setStatus(entity, status, url) {
  return async (dispatch) => {
    dispatch(setStatusRequest());
    return axios
      .patch(`${Config.api.endpoint}/${entity}/${url}`, {
        status: status ? 'active' : 'inactive',
      })
      .then((res) => {
        dispatch(handleUpdateStatus(entity, status ? 'active' : 'inactive'));
        dispatch(setStatusSuccess(res));
        dispatch(setToastSuccess());
      })
      .catch((err) => {
        dispatch(setStatusFailure(err));
        dispatch(setToastFailure({ err: err.response.data.data.message }));
      });
  };
}

export function endEmergency(entity, payload, url) {
  return async (dispatch) => {
    dispatch(setStatusRequest());
    return axios
      .patch(`${Config.api.endpoint}/${entity}/${url}`, payload)
      .then((res) => {
        dispatch(handleUpdateStatus(entity, payload));
        dispatch(getActiveEmergencies());
        dispatch(setStatusSuccess(res));
        dispatch(setToastSuccess());
      })
      .catch((err) => {
        dispatch(setStatusFailure(err));
        dispatch(setToastFailure({ err: err.response.data.data.message }));
      });
  };
}

function handleUpdateStatus(entity, payload) {
  switch (entity) {
    case 'visitors':
      return updateVisitor({ status: payload });
    case 'companies':
      return updateCompany({ status: payload });
    case 'gates':
      return updateGate({ status: payload });
    case 'sites':
      return updateSite({ status: payload });
    case 'users':
      return updateUser({ status: payload });
    case 'locations':
      return updateMusterLocation({ status: payload });
    case 'emergencies':
      return updateEmergency(payload);
    default:
      return;
  }
}

export const ACK_TOAST = 'ACK_TOAST';
export function ackToast() {
  return {
    type: ACK_TOAST,
  };
}

export const HANDLE_STATUS_REQUEST = 'HANDLE_STATUS_REQUEST';
export function handleStatusRequest(payload) {
  return {
    type: HANDLE_STATUS_REQUEST,
    payload,
  };
}

export const CANCEL_STATUS_REQUEST = 'CANCEL_STATUS_REQUEST';
export function cancelStatusRequest() {
  return {
    type: CANCEL_STATUS_REQUEST,
  };
}

export const SET_STATUS_REQUEST = 'SET_STATUS_REQUEST';
function setStatusRequest() {
  return {
    type: SET_STATUS_REQUEST,
  };
}

export const SET_STATUS_SUCCESS = 'SET_STATUS_SUCCESS';
function setStatusSuccess(data) {
  return {
    type: SET_STATUS_SUCCESS,
    data,
  };
}

export const SET_STATUS_FAILURE = 'SET_STATUS_FAILURE';
function setStatusFailure(err) {
  return {
    type: SET_STATUS_FAILURE,
    err,
  };
}
