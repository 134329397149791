import MainCard from "@alliancesafetycouncil/asc-card";
import React from "react";
import { Col, Form, FormGroup, Label, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import ASCButton from "@alliancesafetycouncil/asc-button";

let ChangePassword = ({ handleSubmit, handlePasswordVisibility, showPassword }) => (
  <Form onSubmit={handleSubmit}>
    <MainCard type="main">
      <Row>
        <Col md={6} className="add-col user-col-three">
          <FormGroup>
            <Label for="password" className="primary-heading">
              Password
            </Label>
            <Field
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              placeholder=""
              component="input"
              className="form-control"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="add-col">
          <FormGroup>
            <Label for="password2" className="primary-heading">
              Re-Enter Password
            </Label>
            <Field
              type={showPassword ? "text" : "password"}
              name="password2"
              id="password2"
              placeholder=""
              component="input"
              className="form-control"
            />
          </FormGroup>
        </Col>
        <Col md={6} className="add-col user-col-three" style={{ display: "flex", alignItems: "flex-end" }}>
          <FormGroup>
            <ASCButton type="secondary" size="md" onClick={handlePasswordVisibility}>
              {showPassword ? "Hide" : "Show"} Password
            </ASCButton>
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col lg={12}>
          <FormGroup>
            <div className="flex flex-row">
              <div className="mr-2">
                <ASCButton type="save" size="md" onClick={handleSubmit}>
                  Save
                </ASCButton>
              </div>
              <div>
                <ASCButton type="tertiary" size="md" href="#/profile">
                  Cancel
                </ASCButton>
              </div>
            </div>
          </FormGroup>
        </Col>
      </Row>
    </MainCard>
  </Form>
);

ChangePassword = reduxForm({
  form: "changePassword",
})(ChangePassword);

export default ChangePassword;
