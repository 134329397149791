export const ACK_TOAST = "ACK_TOAST";
export function ackToast() {
  return {
    type: ACK_TOAST,
  };
}

export const SET_TOAST_SUCCESS = "SET_TOAST_SUCCESS";
export function setToastSuccess() {
  return {
    type: SET_TOAST_SUCCESS,
  };
}

export const SET_TOAST_FAILURE = "SET_TOAST_FAILURE";
export function setToastFailure(config) {
  return {
    type: SET_TOAST_FAILURE,
    config,
  };
}
