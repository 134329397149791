import PageTitle from '@alliancesafetycouncil/asc-page-title';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem, Toast, ToastBody } from 'reactstrap';
import { ackToast } from '../../../actions/Status/Status';
import { getVisitor } from '../../../actions/Visitors/Visitors';
import TabDisplay from '../../../components/TabDisplay/TabDisplay';
import DetailsTab from './DetailsTab';

class VisitorDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      config: [
        {
          name: 'Details',
          path: '/details',
          component: DetailsTab,
          default: true,
          exact: true,
        },
      ],
    };
  }

  componentWillMount() {
    const { id, networkId } = this.props.match.params;

    this.props.GetVisitor(id, networkId || null);
  }

  render() {
    const { AckToast, busy, res, err, details, role } = this.props;

    const { company, name } = details;
    const { networkId } = this.props.match.params;

    const isSuccess = !busy && res;
    const isFailure = !busy && err;

    if (isSuccess || isFailure) {
      const timer = setTimeout(() => {
        AckToast();
        clearTimeout(timer);
      }, 5000);
    }

    return (
      <div className="flex flex-1 flex-col">
        <Breadcrumb tag="nav" listTag="div">
          {role !== 'siteadmin' && (
            <BreadcrumbItem
              tag="a"
              href={
                company && company.id
                  ? `/#/companies/company-details/${networkId}/${company.id}/activity-report`
                  : '/#/companies'
              }
            >
              {(company && company.name) || 'Companies'}
            </BreadcrumbItem>
          )}
          <BreadcrumbItem>
            {name.first} {name.last}
          </BreadcrumbItem>
        </Breadcrumb>
        <PageTitle icon="user">
          {name.first} {name.last}
        </PageTitle>
        <TabDisplay {...this.props} tabs={this.state.config} />
        <Toast onClick={AckToast} isOpen={isSuccess} className="toast success">
          <ToastBody>Success!</ToastBody>
        </Toast>
        <Toast onClick={AckToast} isOpen={isFailure} className="toast error">
          <ToastBody>
            <p>{err && err.message}</p>
          </ToastBody>
        </Toast>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    busy: state.statusService.busy,
    res: state.statusService.res,
    err: state.statusService.err,
    details: state.visitorService.details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetVisitor: (userId, networkId) => dispatch(getVisitor(userId, networkId)),
    AckToast: () => dispatch(ackToast()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VisitorDetails);
