import React from "react";
import { Toast, ToastBody } from "reactstrap";

const ToastMessage = ({ toastConfig, ack }) => {
  const { success, failure, err } = toastConfig;

  if (success || failure) {
    const timer = setTimeout(() => {
      ack();
      clearTimeout(timer);
    }, 5000);
  }

  return (
    <div>
      <Toast onClick={ack} isOpen={success} className="toast success">
        <ToastBody>Success!</ToastBody>
      </Toast>
      <Toast onClick={ack} isOpen={failure} className="toast error">
        <ToastBody>
          <p>{err}</p>
        </ToastBody>
      </Toast>
    </div>
  );
};

export default ToastMessage;
