import React, { Component } from "react";

export class CellLink extends Component {
  render() {
    const value = this.props.value;
    const prefix = this.props.prefix;
    const hRef = prefix + value;

    return (
      <a className="text-blue-700 underline" href={hRef}>
        {value}
      </a>
    );
  }
}
